<template>
  <H3>
    <div>Go to the most recent game version at:
      <a href="https://avalon.golemindustrial.com/">Avalon</a>
    </div>

    <div>Find the dice tool here:
      <a href="https://golemindustrial.com/AvalonDice/dice.html">Avalon Dice</a>
    </div>
    <br>
    <div>Or Download PDFs below:</div>
  </H3>
  <hr>
  <h4>System</h4>
  <div>
    <a href="/avalon/pdf/AvalonCharGen.pdf">Character Generation</a>
  </div>
  <div>
    <a href="/avalon/pdf/Equipment_Catalog.pdf">Holiday Equipment Catalog</a>
  </div>
  <div>
    <a href="/avalon/pdf/AvalonCharGen.pdf">Sasaki Exceptional Imports</a>
  </div>
  <div>
    <a href="/avalon/pdf/automatons.pdf">Automatons</a>
  </div>
  <div>
    <a href="/avalon/pdf/rules.pdf">Game Rules</a>
  </div>
  <div>
    <a href="/avalon/pdf/programming.pdf">Programming Rules</a>
  </div>
  <div>
    <a href="/avalon/pdf/quick_reference.pdf">Quick Reference</a>
  </div>
  <div>
    <a href="/avalon/pdf/new_damage.pdf">Updated Weapon Damage</a>
  </div>
  <div>
    <a href="/avalon/pdf/character_sheet_front.pdf">Character Sheet Front</a>
  </div>
  <div>
    <a href="/avalon/pdf/character_sheet_rogue.pdf">Rogue Character Sheet Front</a>
  </div>

  <h4>Story Elements</h4>
  <div>
    <a href="/avalon/beacon/beacon.pdf">Beacon February 6th</a>
  </div>
  <div>
    <a href="/avalon/beacon/7th.pdf">Beacon February 7th</a>
  </div>
  <div>
    <a href="/avalon/beacon/7th_extra.pdf">Beacon February 7th Extra</a>
  </div>
  <div>
    <a href="/avalon/beacon/10th.pdf">Beacon February 10th</a>
  </div>
  <hr>
  <div>
    <a href="/avalon/story/Avalon_Travel_Brochure.pdf">Avalon</a>
  </div>
  <div>
    <a href="/avalon/story/Avalon_Layout.pdf">Avalon Map</a>
  </div>
  <div>
    <a href="/avalon/story/executive_order_7086.pdf">Executive Order</a>
  </div>
  <div>
    <a href="/avalon/story/pk_recruitment_flyer.pdf">PK</a>
  </div>
  <div>
    <a href="/avalon/story/1930s.pdf">Slang</a>
  </div>

</template>

<script>
export default {
  name: 'Avalon_View',
  computed: {

  },
  mounted() {
    console.log('avalon view is mounted')
    this.$store.commit("set_page", 'avalon')
  },
};
</script>

<style>

</style>
