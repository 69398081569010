function get_base_level(attributes, skill) {
    let level = 0
    if(skill.base){
        level = Math.floor(attributes[skill.base].level / 2)
    }
    return level
}

async function get_hash(value) {
    const encoder = new TextEncoder();
    const data = encoder.encode(JSON.stringify(value));
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashString = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    console.log('get hash returning: ', hashString)
    return hashString;
}

function complete_char_json(char) {
    if (typeof char.description === "string") {
        char.description = JSON.parse(char.description)
    }
    if (typeof char.data === "string") {
        char.data = JSON.parse(char.data)
    }
    return char
}

function get_id(){
    const timestamp = new Date().getTime().toString(16)
    const random = Math.floor(Math.random() * 1000000).toString(16)

    return `${timestamp}-${random}`;
}

function get_rollable_skill_level(skills, attributes, target_skill){
    console.log('finding the level for: ', target_skill)
}

export {
    get_base_level,
    get_hash,
    complete_char_json,
    get_id,
    get_rollable_skill_level
}
