<template>
  <h3>Printable Character Sheets</h3>
  <div>
    <a href="/torn_moon/files/sheet_front.pdf">Character Sheet Front</a>
  </div>
  <div>
    <a href="/torn_moon/files/sheet_front_maker.pdf">Sphere Maker Character Sheet</a>
  </div>
  <div>
    <a href="/torn_moon/files/sheet_back.pdf">Character Sheet Reverse</a>
  </div>


</template>

<script>

export default {
  name: 'Docs_Printables_Component',
  data() {
    return {
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
