<template>
  <h3>Unarmed Combat Damage</h3>

  <p>
    Unarmed combat damage is mostly irrelevant.  It has only been used about 3 times in 20 years.
  </p>

  <p>
    Unarmed combat damage and class are derived based on the level of the Strength and Speed Core Attributes as follows:
  </p>

  Strength Level for Damage:
  <ul>
    <li v-for="(val, index) in damage.strength" :key="index">{{index}}: {{val}}</li>
  </ul>

  Speed Level for Class:
  <ul>
    <li v-for="(val, index) in damage.speed" :key="index">{{index}}: {{val}}</li>
  </ul>
</template>

<script>
import gen from "@/data/torn_moon_data/char_generation.json"
export default {
  name: 'TM_Char_Gen_Unarmed_Damage',
  data() {
    return{
      damage: gen.unarmed_combat
    }
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
