<template>
  <h3>Artifact Weapons</h3>
  <h3>Sacred Artifact Equipment</h3>
  <p>
    All of the weapons listed here involve Parent technology, typically these items would not be used by Sphere
    Makers.
  </p>

  <p>
    Distribution of sacred artifacts is tightly controlled, as such purchasing these items at character generation time
    requires a succesful availability roll.
  </p>


  <div v-for="(category, index) in weapons" :key="index" >
    <h4>{{index}}</h4>
    <table class="basic_table">
      <tr>
        <th>
          Weapon Name
        </th>
        <th>Description</th>
        <th>Price</th>
        <th>Availability</th>
        <th>Damage</th>
        <th>Damage Type</th>
        <th>Class</th>
        <th>Penetration</th>
        <th v-if="category[0].range">Range</th>
        <th>Connector</th>
        <th>Power Use</th>
        <th>1 Handed/2 Handed</th>
      </tr>
      <tr v-for="(eq_obj, index) in category" :key="index">
        <td>{{ eq_obj.title }}</td>
        <td>{{ eq_obj.description }}</td>
        <td>{{ eq_obj.price || "N/A" }}</td>
        <td>{{ eq_obj.availability || "all"}}</td>
        <td>{{eq_obj.damage || "N/A"}} </td>
        <td>{{ eq_obj.damage_type || "N/A"}}</td>
        <td>{{ eq_obj.class || "N/A"}}</td>
        <td>{{eq_obj.penetration || "N/A"}}</td>
        <td v-if="category[0].range">{{eq_obj.range || "N/A"}}</td>
        <td>{{eq_obj.connector || "N/A"}}</td>
        <td>{{eq_obj.power_use || "N/A"}}</td>
        <td>{{ eq_obj.hands || "N/A"}}</td>
      </tr>
    </table>
  </div>



</template>

<script>
import weapons from "@/data/torn_moon_data/weapons.json"

export default {
  name: 'TM_EQ_Sacred_Weapons',
  data() {
    return {
      weapons: weapons.sacred_weapons
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
