import races_list from "@/data/torn_moon_data/playable_races.json"

function get_race_obj_by_name(race_name){
    for (const index in races_list){
        const race = races_list[index]
        if(race.name === race_name){
            return race
        }
    }
}

export {
    get_race_obj_by_name
}
