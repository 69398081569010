<template>
  <div class="games_selector_block">
    <label for="game_select">Select the game:</label>
    <select id="game_select" v-model="selected_game" @change="select_game">
      <option value="">Select an option</option>
      <option v-for="game in games_data" :key="game.name" :value="game">{{ game.title }}</option>
    </select>
    <p v-if="selected_game">You selected a game: {{ selected_game }}</p>
  </div>
</template>

<script>
import games_data from '@/data/golem_industrial/game.json'
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();

    const set_selected_game = (game_obj) => {
      console.log('setting the selected game in the store: ', game_obj)
      store.commit('set_game', game_obj)
    };

    return {
      set_selected_game:set_selected_game,
    };
  },
  data(){
    return{
      selected_game: '',
      games_data:games_data
    }
  },
  computed:{

  },
  props: []
  ,
  methods: {
    select_game(){
      console.log('Selected game changed to:', this.selected_game)
      this.set_selected_game(this.selected_game)
    }
  },
};
</script>

<style scoped>
.games_selector_block {
  border: 1px solid #ccc;
}
</style>
