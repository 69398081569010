<template>
  <h3>Common Weapons</h3>
  <p>
    .
  </p>

  <p>
    Common weapons are mostly readily available to all races. There are some non standard materials such as Orkett that
    do require availabilty checks.
  </p>

  <div v-for="(category, index) in weapons" :key="index" >
    <h4>{{index}}</h4>
    <table class="basic_table">
      <tr>
        <th>
          Weapon Name
        </th>
        <th>Description</th>
        <th>Price</th>
        <th>Availability</th>
        <th>Damage</th>
        <th>Class</th>
        <th>Damage Type</th>
        <th>Penetration</th>
        <th v-if="category[0].range">Range</th>
        <th>1 Handed/2 Handed</th>
      </tr>
      <tr v-for="(eq_obj, index) in category" :key="index">
        <td>{{ eq_obj.title }}</td>
        <td>{{ eq_obj.description }}</td>
        <td>{{ eq_obj.price || "N/A" }}</td>
        <td>{{ eq_obj.availability || "all"}}</td>
        <td>{{eq_obj.damage || "N/A"}} </td>
        <td>{{eq_obj.class || "N/A"}}</td>
        <td>{{ eq_obj.damage_type || "N/A"}}</td>
        <td>{{eq_obj.penetration || "N/A"}}</td>
        <td v-if="category[0].range">{{eq_obj.range || "N/A"}}</td>
        <td>{{ eq_obj.hands || "N/A"}}</td>
      </tr>
    </table>
  </div>



</template>

<script>
import weapons from "@/data/torn_moon_data/weapons.json"

export default {
  name: 'TM_EQ_Basic_Weapons',
  data() {
    return {
      weapons: weapons.standard
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
