import CONSTS from "@/data/consts";

const axios = require('axios');
function log_payload(type, payload_obj){
    console.log('%c Sending ' + type + ' ' + JSON.stringify(payload_obj), 'background: whitesmoke; color: blue')
}

async function db_login(username, password, context) {
    const loginData = {
        username: username,
        password: password,
    };

    try {
        const response = await axios.post(`${CONSTS.server}:${CONSTS.server_port}/login`, loginData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {
            const data = response.data;

            if (data.user) {
                data.user.account = username;
                console.log('login operation got user data: ', data.user);
                return {
                    user: data.user,
                    token: data.token,
                };
            } else {
                console.log('response not ok');
                throw new Error('Invalid login credentials.');
            }
        } else {
            throw new Error('Network response was not ok.');
        }
    } catch (error) {
        console.log('error hitting login endpoint:', error);
        context.errorMessage = error.message;
    }
}

async function db_fetch_characters(user_id, token, game_name) {
    console.log('fetching characters for ', game_name);
    const payload = {"user_id": user_id, "token": token, "game": game_name};
    log_payload('fetch character', payload)
    try {
        const response = await axios.post(`${CONSTS.server}:${CONSTS.server_port}/get_characters`, payload, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = response.data;
        console.log('pulled characters: ', data.characters);
        return data.characters;
    } catch (error) {
        console.error('Error fetching characters:', error);
    }
}

async function db_delete_character(user_id, token, game_name, character_id) {
    console.log(`deleting character ${character_id} from ${game_name} for ${user_id}`);
    const payload = {
        data_request: {
            user_id: user_id,
            token: token,
            game: game_name
        },
        update: {
            user_id: user_id,
            char_id: character_id,
            game: game_name
        }
    };
    log_payload('delete character', payload)

    try {
        const response = await axios.post(`${CONSTS.server}:${CONSTS.server_port}/delete_character`, payload, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const success = response.status === 200
        console.log('delete succeeded: ', success);
        return success;
    } catch (error) {
        console.error('Error deleting character:', error);
    }
}

async function db_insert_character(user_id, token, game_name, character_obj) {
    console.log(`inserting new character ${character_obj.name} for ${user_id} to use in ${game_name}`);
    const payload = {
        data_request: {
            user_id: user_id,
            token: token,
            game: game_name
        },
        character: {
            user_id: user_id,
            name: character_obj.name,
            description: character_obj.description,
            data: character_obj.data,
            game: game_name
        }
    };
    console.log('consts: ', CONSTS);
    log_payload('insert character', payload)

    try {
        const response = await axios.post(`${CONSTS.server}:${CONSTS.server_port}/insert_character`, payload, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = response.data;
        console.log('insert succeeded: ', data);
        return true;
    } catch (error) {
        console.error('Error inserting character:', error);
        return false
    }
}

async function db_update_character(user_id, token, game_name, character_obj) {
    console.log(`updating existing character ${character_obj.name} for ${user_id} to use in ${game_name}`);
    const payload = {
        data_request: {
            user_id: user_id,
            token: token,
            game: game_name
        },
        update: {
            char_id: character_obj.char_id,
            data: character_obj.data,
            game: game_name,
            description: character_obj.description
        }
    };
    log_payload('update character', payload)

    try {
        const response = await axios.post(`${CONSTS.server}:${CONSTS.server_port}/update_character`, payload, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = response.data;
        console.log('update succeeded: ', data);
        return true;
    } catch (error) {
        console.error('Error updating character:', error);
    }
}

async function db_ping() {
    console.log('checking ping to api');
    try {
        const response = await axios.get(`${CONSTS.server}:${CONSTS.server_port}/ping`);

        console.log('ping test returned: ', response.data);
        return response.data.characters;
    } catch (error) {
        console.error('Error sending ping:', error);
    }
}

//hoisting ping up to be accessible from the command line
window.ping = db_ping

export {
    db_login,
    db_fetch_characters,
    db_delete_character,
    db_insert_character,
    db_update_character,
    db_ping
}
