<template>
  <div>
    <h1>Login</h1>
    <Login_Component />
  </div>
</template>

<script>
import Login_Component from "@/components/Login_Component.vue";

export default {
  name: 'Login_View',
  components: {
    Login_Component,
  },
  methods: {

  },
  mounted() {
    console.log('login view is mounted')
    this.$store.commit("set_page", 'login')
  },
};
</script>

<style>
h1 {
  font-size: 24px;
  margin-bottom: 10px;
}
</style>
