import attributes from '@/data/torn_moon_data/attributes.json';
import desc_default from '@/data/torn_moon_data/default_description.json'
import move_default from '@/data/torn_moon_data/default_movement.json'
import hp_default from '@/data/torn_moon_data/default_hp.json'

function build_char_skeleton() {
    let char = {
        char_id: null,
        name: null,
        created_date: null,
        dead: 0,
        game: 'torn moon',
        last_game_id: 0,
        description: [],
        data: {
            attributes: {},
            movement: [],
            skills: [],
            hit_points: [],
            life_force: {},
            weapons: [],
            equipment: [],
            chips: 0,
            notes: []
        },
    }
    attributes.forEach((attribute) => {
        char.data.attributes[attribute.abbreviation] = build_attribute_skeleton(attribute)
    })


    const new_desc = JSON.stringify(desc_default.new)
    desc_default.default_description_titles.forEach((title)=>{
        console.log("desc title: ", title)
        let d = JSON.parse(new_desc)
        d.title = title
        char.description.push(d)
    })

    const new_move = JSON.stringify(move_default.new)
    move_default.default_movement_titles.forEach((title)=>{
        let m = JSON.parse(new_move)
        m.title = title
        char.data.movement.push(m)
    })

    const new_hp = JSON.stringify(hp_default.new)
    hp_default.default_hp_location_titles.forEach((title)=>{
        let h = JSON.parse(new_hp)
        h.title = title
        char.data.hit_points.push(h)
    })
    return char
}

function build_attribute_skeleton(attribute) {
    return {
        "title": attribute.title,
        "abbr": attribute.abbr,
        "type": attribute.type,
        "level": 0,
        "hash": 0
    }
}

export {
    build_char_skeleton,
    build_attribute_skeleton
}
