<template>
  <div class="power_block">
    <div>
      <span class="cell_title">{{ cell_obj.type }} - {{cell_obj.connector}}</span>&nbsp;
      Max <input type="number" :value="cell_obj.max"
                   @change="updateFormField(key, $event.target.value)"/>
      Max <input type="number" :value="cell_obj.current"
                 @change="updateFormField(key, $event.target.value)"/>
      Equipped: true/false
      <button>Equip</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['cell_obj', 'weapons'],
  computed: {},
  methods: {},
};
</script>

<style scoped>
.power_block {
  border: 1px solid #ccc;
}
</style>
