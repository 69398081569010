// router.js
// import Vue from 'vue';
// import VueRouter from 'vue-router';
import {createRouter, createWebHistory} from 'vue-router'
import store from '@/store';
import Home from './views/Home_View.vue';
import Login_View from './views/Login_View.vue'
import Characters_View from "@/views/Characters_View";
import Account_View from "@/views/Account_View";
import Torn_Moon_View from "@/views/torn_moon/Torn_Moon_View.vue";
import Avalon_View from "@/views/avalon/Avalon_View.vue";
import Torn_Moon_Sheet_View from "@/views/torn_moon/Torn_Moon_Sheet_View.vue";


const routes = [
    {
        path: '/',
        name: 'Root Home',
        component: Home,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login_View,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/account',
        name: 'Account',
        component: Account_View,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/characters',
        name: 'Characters',
        component: Characters_View,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/torn_moon',
        name: 'Torn Moon',
        component: Torn_Moon_View,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/torn_moon_sheet',
        name: 'Torn Moon Sheet',
        component: Torn_Moon_Sheet_View,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/avalon',
        name: 'Avalon',
        component: Avalon_View,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/test',
        name: 'test',
        component: Torn_Moon_Sheet_View,
        meta: {
            requiresAuth: false,
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


//to is an object that shows the path of the intended route
//from is an object that shows the path of the referrer
//next is a callback function, guessing its supposed to navigate a limited number of times
router.beforeEach((to, from, next) => {
    //simple insecure client side check allows navigation, but will not allow any content to be downloaded from the server
    const isAuthenticated = store.getters.token;
    console.log('router checking auth.  Is authenticated: ', isAuthenticated)
    if (to.matched.some(record => record.meta.requiresAuth)) {
        console.log('router identified destination requires authentication: ', to.path)
        console.log('authentication is currently ' + isAuthenticated + ' store is currently: ', store)
        // Route requires authentication
        if (!isAuthenticated) {
            console.log('user is not authenticated, redirecting to login')
            next({ name: 'Login' }); // Redirect to Login view if not authenticated
        } else {
            console.log('user is authenticated proceedging to destination: ', to.path)
            next(); // Proceed to the route
        }
    } else {
        console.log('router identified destination does not require authentication: ', to.path)
        next(); // Route doesn't require authentication
    }
});

export default router;
