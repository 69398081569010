<template>
  <div>
    <h1>The Golem Welcomes you to The Nexus</h1>
    <p>Login to access his content</p>
  </div>
</template>

<script>
export default {
  name: 'Home_View',
  mounted() {
    console.log('home view is mounted')
    this.$store.commit("set_page", 'home')
  },
}
</script>

<style>
h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
}
</style>
