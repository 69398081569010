<template>
  <h3>Character Description</h3>
  <p>
    Once your base attributes have been determined, some of these values are used to round out the
    character and calculate new pools of points to spend.
  </p>

  <h4>Name</h4>
  <p>Naming your character is a personal decision. Torn Moon allows for a variety of names for most races. Choose
    whatever fits you</p>
  <p>Land Walkers however have very strict naming conventions. If you stray outside of those conventions it will say
    something about your character and/or their upbringing. Land walker names are hyphenated two syllable combinations.
    The first syllable is the given name granted by their parents at birth. The second syllable is the given name of the
    clan leader at the time of their birth. Clans are very important to Land Walkers, and even Walkers who live in the
    newer multicultural cities will still carry a clan identity, and identify a leader of the clan for that area for
    whom their child must be named. Land walkers do not shorten their names, nor do they call each other by only the
    given name. It would be disrespectful to their clan.</p>

  <h4>Age</h4>
  <p>
    Age does not statistically affect game play, however it can come in to account during actual
    role play. Age is determined as follows: {{ age_formula }}
  </p>

  <h4>Height and Weight</h4>
  <p>
    Height affects mostly the way in which a character is perceived and is not
    directly a factor in much that goes on around the character or his statistics, load however
    becomes a factor if and when I decide to care about it. Don't carry around a sack full of grand pianos because I
    don't want to deal with it.
  </p>

  <p>
    Weight is stupid and I want to ignore it. I only need to figure it out if you make me figure out load.
  </p>


</template>

<script>
import char_gen from "@/data/torn_moon_data/char_generation.json"
import races from "@/data/torn_moon_data/playable_races.json"
// import attributes from "@/data/torn_moon_data/attributes.json"

export default {
  name: 'TM_Char_Gen_Description',
  data() {
    return {
      races: races,
      age_formula: char_gen.age_calc
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
