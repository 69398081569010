<template>
  <h3>Transport</h3>

  <h3>Animal Transportation</h3>
  <table class="basic_table">
    <tr>
      <th>Type</th>
      <th>Training</th>
      <th>Movment</th>
      <th>Hit Points</th>
      <th>Description</th>
      <th>Price</th>
    </tr>
    <tr v-for="(eq_obj, index) in animals" :key="index">
      <td> {{ eq_obj.type }}</td>
      <td>{{ eq_obj.training }}</td>
      <td>{{ eq_obj.movement }}</td>
      <td>{{ eq_obj.hit_points }}</td>
      <td>{{ eq_obj.price }}</td>
      <td>{{ eq_obj.description }}</td>
    </tr>
  </table>

  <h3>Sacred Artifact Transportation</h3>
  <table class="basic_table">
    <tr>
      <th>Type</th>
      <th>Availability</th>
      <th>Power</th>
      <th>Price</th>
      <th>Connector</th>
      <th>Power Cells Required</th>
      <th>Description</th>
    </tr>
    <tr v-for="(eq_obj, index) in artifact" :key="index">
      <td> {{ eq_obj.title }}</td>
      <td>{{ eq_obj.availability }}</td>
      <td>{{ eq_obj.power }}</td>
      <td>{{ eq_obj.price }}</td>
      <td>{{ eq_obj.connector }}</td>
      <td>{{ eq_obj.power_cells_required }}</td>
      <td>{{ eq_obj.description }}</td>
    </tr>
  </table>

</template>

<script>
import transport from "@/data/torn_moon_data/transportation.json"

export default {
  name: 'TM_Transport',
  data() {
    return {
      animals: transport.animal,
      artifact: transport.artifact
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
