<template>
  <nav v-if="isVisible" class="navigation">
    <div>
      <h3>Golem Industrial Nexus</h3>
    </div>
    <div class="left-icons">
      <router-link to="/account" class="nav-link" title="Account Details">👤</router-link>
      <router-link to="/characters" class="nav-link" title="Your Characters">👥</router-link>
      <a v-if="authenticated" href="#" class="nav-link" @click="logout">Logout</a>
      <router-link v-else to="/login" class="nav-link">Login</router-link>
    </div>

    <div class="link-bar">
      <router-link to="/home" class="nav-link">Home</router-link>
      <router-link to="/avalon" class="nav-link">Avalon</router-link>
      <router-link to="/torn_moon" class="nav-link">Torn Moon</router-link>
      <router-link to="/test" class="nav-link">Test</router-link>
<!--      <button @click="ping">Ping</button>-->
    </div>

    <div class="bottom-right">
      <button @click="toggleVisibility">⬆</button>
    </div>
  </nav>
  <div v-if="!isVisible" class="empty_navigation">
    <div class="right">
      <button @click="toggleVisibility">⬇</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Navigation_Component',
  data() {
    return {
      isVisible: true,
    };
  },
  computed: {
    authenticated() {
      return 'user' in window
    }
  },
  methods: {
    toggleVisibility() {
      console.log('toggling nav bar to ', !this.isVisible)
      this.isVisible = !this.isVisible;
      this.$store.commit('set_nav_visible', this.isVisible)
    },
    logout() {
      console.log('Logging out...');
      window.user = null
      this.$router.push('/home');
    },
  },
};
// window.nav_toggle = new CustomEvent('nav_toggle')
</script>

<style>
.navigation {
  background-color: #DBE5D3FF;
  color: #050505;
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  top: 0;
  left: 0;
//z-index: 999;
}
@font-face {
  font-family: 'header';
  src: url('../assets/Chonburi-Regular.ttf') format('truetype');
}

h3 {
  font-family: header, sans-serif;
}

.empty_navigation {
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.link-bar {
  margin-top: 1rem;
}

.nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}


a {
  color: #050505;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.nav-icon {
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.fa-bars {
  color: #050505;
  font-size: 1.5rem;
}

.left-icons {
  margin-bottom: auto;
  align-self: flex-start;
  padding-left: 3rem;
}

.bottom-right {
  margin-top: auto;
  align-self: flex-end;
  padding-right: 1rem;
}

.right {
  margin-top: auto;
  align-self: flex-end;
  padding-right: 1rem;
}
</style>
