<template>
  <div class="character-component">
    <h3>{{ character.name }}</h3>
    <button @click="select_me">Select</button>
    <button @click="delete_me">Delete</button>
  </div>
</template>

<script>
import {db_delete_character} from "@/js/db_ops";
import {complete_char_json} from "@/js/parsing_functions";

export default {
  props: ['modelValue'],
  computed: {
    character() {
      return this.modelValue
    },
    user() {
      return this.$store.state.user || {'alias': 'empty_test'}
    },
  },
  methods: {
    async delete_me() {
      const result = await db_delete_character(this.$store.state.user.user_id, this.$store.state.token, this.$store.state.selected.game.name, this.character.char_id)
      console.log('delete_me got a result: ', result)
      if (result){
        this.$emit('char_delete', this.character.char_id)
      }
    },
    select_me() {
      // if (typeof this.character.data === 'string') {
      //   console.log('character data is string, parsing')
      //   let new_char = JSON.parse(JSON.stringify(this.character))
      //   new_char.data = JSON.parse(new_char.data)
      //   this.$store.commit('set_character', new_char)
      // } else {
      //
      // }
      this.character = complete_char_json(this.character)
      console.log(this.$store.state.user.alias + " selected " + JSON.stringify(this.character))
      this.$store.commit('set_character', this.character)
      this.$router.push({name: 'Torn Moon Sheet'})
    },
  },
};
</script>

<style scoped>
.character-component {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}
</style>
