<template>
  <div class="skill_block">
    <input v-if="skill.class === 'Game Master'" type="text" v-model="skill.title"/>
    <span v-else class=" skill_title">{{ skill.title }}: </span>
    Level <input type="number" v-model="skill.level" class="standard_short_input"
                 @change="$emit('append_log', skill.title + ' level changed to ' + $event.target.value + '\n')"/>

    Hash Marks <input type="number" v-model="skill.hash" class="standard_short_input"
                      @change="$emit('hash_update', skill.title + ' hashes changed to ' + $event.target.value + '\n')"/>
    <template v-if="skill.level === 0">
      Base ({{ skill.base }}) <input type="text" :value="get_base_level(attributes, skill)" class="standard_short_input"
                                     @change="$emit('base_update', $event.target.value)"/>
    </template>
    <button @click="roll(skill)" class="spaced_3">🎲</button>
    <button @click="$emit('skill_delete', skill)">Delete</button>
  </div>
</template>

<script>
import {tm_basic_roll, tm_base_roll} from "@/js/torn_moon/roll_functions";
import {get_base_level} from "@/js/parsing_functions";

export default {
  props: ['modelValue', 'attributes']
  ,
  data() {
    return {}
  },
  computed: {
    skill() {
      return this.modelValue
    }
  },
  methods: {
    get_base_level,
    roll() {
      let roll
      if (this.skill.level > 0) {
        roll = tm_basic_roll(this.skill)
      } else {
        roll = tm_base_roll(this.attributes, this.skill)
      }
      console.log('Roll for ' + this.skill.title + ' got back: ', roll)
      if (roll.hash) {
        this.skill.hash += roll.hash
      }
      this.$emit('append_log', roll.log)
    }
  },
};
</script>

<style scoped>
.skill_block {
  border: 1px solid #ccc;
}
</style>
