<template>
  <div class="etheria_block">
    <span class="etheria_title">{{ etheria_obj.ethos }} - {{etheria_obj.title}}</span>
    Method: {{etheria_obj.method}}
    Level <input type="number" :value="etheria_obj.level"
                 @change="updateFormField(key, $event.target.value)"/>
    Hash Marks <input type="number" :value="etheria_obj.hash"
                      @change="updateFormField(key, $event.target.value)"/>
    Infused true/false how much
    <button>🎲</button>
  </div>
</template>

<script>
export default {
  props: ['etheria_obj', 'attributes']
  ,
  methods: {

  },
};
</script>

<style scoped>
.etheria_block {
  border: 1px solid #ccc;
}

</style>
