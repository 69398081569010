<template>
  <div class="equipment_block">
    <div>
      <span class="equipment_title">{{ eq_obj.title }}</span>&nbsp;
      Equipped: true/false
      <div v-if="eq_obj.power">
        Power {{eq_obj.power}}
        Connector {{eq_obj.connector}}
      </div>
      <div>{{eq_obj.note}}</div>
      <button>Equip</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['eq_obj'],
  computed: {},
  methods: {},
};
</script>

<style scoped>
.equipment_block {
  border: 1px solid #ccc;
}
</style>
