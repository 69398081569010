<template>
  {{user.alias}}'s {{ selected_game.title }} Characters
  <button @click="new_character">New {{ selected_game.title }} Character</button>
  <div v-for="(char_obj, index) in characters" :key="index">
    <Character_Component v-model="characters[index]" @char_delete="characters.splice(index, 1)"/>
  </div>
</template>

<script>
import Character_Component from './Character_Component.vue';
import {build_char_skeleton} from "@/js/torn_moon/skeletons";
import {db_fetch_characters} from "@/js/db_ops";

export default {
  name: 'Character_Grid_Component',
  props: ['selected_game'],
  components: {
    Character_Component,
  },
  computed: {
    characters() {
      return this.$store.state.user.characters
    },
    user() {
      return this.$store.state.user || {alias: 'empty_test'}
    },
  },
  data() {

  },
  mounted() {
    console.log('mounted, fetching the characters')
    this.fetch_characters();
  },
  methods: {
    new_character() {
      console.log('creating a new character')
      this.$store.commit('set_character', build_char_skeleton())
      this.$router.push({name: 'Torn Moon Sheet'})
    },
    async fetch_characters() {
      console.log('fetching characters for ', this.selected_game)
      let characters = await db_fetch_characters(this.$store.state.user.user_id, this.$store.state.token, this.selected_game.name)
      console.log('character grid got characters: ', characters)
      this.$store.commit('set_characters', characters)
    },
  },
};
</script>

<style>
.character-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}
</style>
