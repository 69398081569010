<template>
  <div>
    <p>
      This is the universal Character generation system for all races for use with the {{ version }} edition of Torn
      Moon.</p>
    <p>
      Five races are available for play in Torn Moon Detailed explanations of these races can be found in the
      "Races of the World" section.
    </p>
    <p>
      For the purposes of this Character Generation document, the
      following abbreviations are supplied.
    </p>
  </div>
  <ul>
    <li class='abbreviation' v-for="(race, index) in races" :key="index">{{ race.title }} : {{ race.abbreviation }}</li>
  </ul>

  <p>
    These abbreviations will be used broadly accross the site when discussing bonuses and limitations applied to
    characters of each race.
  </p>

  <p>
    It is not possible for any character to combine races or inherently gain the advantages of another race. However,
    often there will be items, or other equipment that can temporarily or permanently grant equivalent abilities from one
    race to another.
  </p>

</template>

<script>
import consts from "@/data/torn_moon_data/game_consts.json"
import races from "@/data/torn_moon_data/playable_races.json"

export default {
  name: 'TM_Char_Gen_Intro_Component',

  data() {
    return {
      version: consts.tm_version_title,
      races: races
    };
  },
  methods: {}
};
</script>

<style scoped>

ul {
  list-style: inside;
}

</style>
