<template>
  <div class="weapon_block" @click="select_me">
    <div class="weapon_header">
      <span class="weapon_title">{{ weapon_obj.title }}</span>, Damage: {{ weapon_obj.damage || 'N/A'}}, Damage Type:
      {{ weapon_obj.damage_type || 'N/A' }}, Weapon Class: {{ upper(weapon_obj.class) || 'N/A' }}, Penetration:
      {{ weapon_obj.penetration || 'N/A' }}, Availability: {{ weapon_obj.availability || 'N/A' }}, Price: {{ weapon_obj.price || 'N/A' }}, Hands:
      {{ weapon_obj.hands || 'N/A' }} <span v-if="weapon_obj.ammunition_type">, Ammunition Type: {{ weapon_obj.ammunition_type || 'N/A'}}</span>
      <span v-if="weapon_obj.range">, Range: {{ weapon_obj.range || 'N/A'}}</span>
    </div>
    <div>
      Description: {{ weapon_obj.description }}
    </div>
    <div>
      <span v-if="weapon_obj.connector">Power Cell Connector Type: {{weapon_obj.connector}}</span>
      <span v-if="weapon_obj.power_use">, Power Use: {{weapon_obj.power_use}}</span>
    </div>
    <div v-if="weapon_obj.life_force">
      Weapon Skill: {{weapon_obj.skill}}, Life Force Use: {{weapon_obj.life_force}}
    </div>
  </div>
</template>

<script>
import {get_id} from '@/js/parsing_functions'
export default {
  props: ['weapon_obj'],
  methods: {
    select_me() {
      let weapon = JSON.parse(JSON.stringify(this.weapon_obj))
      weapon.id = get_id()
      console.log("weapon selected: ", weapon)
      this.$emit('weapon_selected', weapon);
    },
    upper(lower) {
      let rv = ''
      if (lower) {
        rv = lower.toUpperCase()
      }
      return rv
    }
  },
};
</script>

<style scoped>
.weapon_block {
  border: 1px solid #ccc;
  margin-bottom: 5px;
}

.weapon_title {
  font-weight: bold;
}
</style>
