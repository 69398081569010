<template>
  <div class="weapon_block">
    <div>
      Weapon: <input type="text" v-model="weapon.title" @change="log_change('Weapon Title', $event.target.value)"/>
      Weapon Skill: <input type="text" v-model="weapon.skill"
                           @change="log_change('Weapon Skill', $event.target.value)"/>

      <span v-if="trained">
        Skill Level: <input type="number" class="standard_short_input" v-model="weapon_skill.level"
                            @change="log_change('Weapon Skill Level', $event.target.value)"/>
      </span>

      <span v-else>
        Untrained Level: <input type="number" class="standard_short_input" v-model="weapon_skill.level"
                            @change="log_change('Weapon Skill Level', $event.target.value)"/>
      </span>

      <button>🎲</button>
    </div>

    <div>
      Damage: <input type="text" v-model="weapon.damage" @change="log_change('Weapon Damage', $event.target.value)"/>
      Damage Type: <input type="text" v-model="weapon.damage_type"
                          @change="log_change('Weapon Damage Type', $event.target.value)"/>
      <button>🎲 damage</button>
      Class <input type="text" v-model="weapon.class" class="standard_short_input"
                   @change="log_change('Weapon Class', $event.target.value)"/>
    </div>

    <div v-if="weapon.ammo_type">
      Ammo Type: {{ weapon.ammo_type }}
      <template v-if="ammunition_obj">
        Equipped: {{ ammunition_obj.type }}
        <button>Unequip</button>
      </template>
    </div>

    <div v-if="weapon.power_use">
      Power Cell Connector Type: <input type="text" v-model="weapon.connector"/>
      Power Use <input type="number" v-model="weapon.power_use" class="standard_short_input"/>
      <template v-if="power_cell_obj">
        Equipped: {{ power_cell_obj.type }}
        <button>Unequip</button>
      </template>
    </div>

    <div v-if="weapon.life_force">
      Life Force: <input type="number" class="standard_short_input" v-model="weapon.life_force"
                         @change="log_change('Weapon Skill Level', $event.target.value)"/>
    </div>


  </div>
</template>

<script>
export default {
  props: ['skills', 'weapon_obj', 'attributes', 'ammunition_obj', 'power_cell_obj'],
  data() {
    return {
      trained:false
    }
  },
  computed: {
    weapon(){
      return this.weapon_obj
    },
    char_skills(){
      window.char_skills = this.skills
      return this.skills
    },
    weapon_skill(){ //right now there is a disconnect in the reactivity of the weapon skill level and the skill skill level
      console.log('checking if trained for: ', this.weapon.skill)
      let char_skill = 0
      this.char_skills.forEach(skill=>{
        console.log("skill: ", skill)
        console.log("char skill: ", char_skill)
        if(skill.title === this.weapon.skill){
          console.log('found this skill at level: ', skill.level)
          char_skill= skill
          this.trained = char_skill.level > 0
          console.log('skill ' + skill.title + ' is trained: ', this.trained)
        }
      })
      return char_skill
    },
  },
  methods: {
    log_change(target, value) {
      this.$emit('append_log', this.weapon_obj.title + ' ' + target + ' changed to ' + value + '\n')
    },
    roll_skill(){

    },
    roll_damage(){

    }
  },
};
</script>

<style scoped>
.weapon_block {
  border: 1px solid #ccc;
}

.weapon_title {
  font-weight: bold;
}
</style>
