<template>
  <h3>Movement</h3>
  <p>
    Movement is an indication of how far a character can travel in a single turn or phase measured in yards.
  </p>
  <p>Combat movement is considered to be half the value depicted below. Combat movement is how far a character can move
    when he is engaged in any action during the same phase he is moving in.</p>

  <p>There are three main types of player movement, though the game master may elect to create other kinds for some
    situations. The basic forms of movement are: </p>

  <table>
    <tr>
      <td>Race</td>
      <td>Standard (Running)</td>
      <td>Swimming</td>
      <td>Flying</td>
    </tr>
    <tr v-for="(race_obj, index) in races" :key="index">
      <td>{{race_obj.title}}</td>
      <td>{{movement[race_obj.name].standard || "N/A"}}</td>
      <td>{{movement[race_obj.name].swimming || "N/A"}}</td>
      <td>{{movement[race_obj.name].flight || "N/A"}}</td>
    </tr>
  </table>

</template>

<script>
import gen from "@/data/torn_moon_data/char_generation.json"
import races from "@/data/torn_moon_data/playable_races.json"

export default {
  name: 'TM_Char_Gen_Movement',
  data() {
    return {
      movement: gen.movement,
      races: races
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
