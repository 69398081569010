<template>
  <h3>Ket Equipment</h3>
  <p>
    Ket Equipment is not typically available for Sphere Makers.
  </p>

  <p>
    Ket are small devices that typically operate in pairs. The ket itself will have a typically violent reaction when
    activated by the conditions dictated by the tag it is synced with. The type of tag controlls the condition under
    which the ket will activate, while the ket itself determines the result of the activation.
  </p>

  <p>
    Failed attempts to use ket equipment can have dangerous or even disasterous results.
  </p>

  <h3>Ket Tools</h3>
  <table class="basic_table">
    <tr>
      <th>Tool Type</th>
      <th>Availability</th>
      <th>Price</th>
      <th>Description</th>
    </tr>
    <tr v-for="(eq_obj, index) in tools" :key="index">
      <td> {{ eq_obj.title }}</td>
      <td>{{ eq_obj.availability }}</td>
      <td>{{ eq_obj.price }}</td>
      <td>{{ eq_obj.description }}</td>
    </tr>
  </table>

  <h3>Ket Equipment</h3>
  <table class="basic_table">
    <tr>
      <th>Ket Type</th>
      <th>Damage</th>
      <th>Availability</th>
      <th>Penetration</th>
      <th>Price</th>
      <th>Count per purchase</th>
      <th>Description</th>
    </tr>
    <tr v-for="(eq_obj, index) in ket_eq" :key="index">
      <td> {{ eq_obj.title }}</td>
      <td>{{ eq_obj.damage }}</td>
      <td>{{ eq_obj.availability }}</td>
      <td>{{ eq_obj.penetration }}</td>
      <td>{{ eq_obj.price }}</td>
      <td>{{ eq_obj.count }}</td>
      <td>{{ eq_obj.description }}</td>
    </tr>
  </table>

  <h3>Ket Equipment</h3>
  <table class="basic_table">
    <tr>
      <th>Ket Type</th>
      <th>Availability</th>
      <th>Price</th>
      <th>Count per purchase</th>
      <th>Description</th>
    </tr>
    <tr v-for="(eq_obj, index) in tags" :key="index">
      <td> {{ eq_obj.title }}</td>
      <td>{{ eq_obj.availability }}</td>
      <td>{{ eq_obj.price }}</td>
      <td>{{ eq_obj.count }}</td>
      <td>{{ eq_obj.description }}</td>
    </tr>
  </table>


</template>

<script>
import weapons from "@/data/torn_moon_data/weapons.json"

export default {
  name: 'TM_Ket_Equipment',
  data() {
    return {
      ket_eq: weapons.ket.ket_equipment,
      tags: weapons.ket.ket_tags,
      tools: weapons.ket.ket_tools
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
