<template>
  <div class="modal-overlay">
    <div class="modal">
      <div class="modal-header">
        <button class="close-button" @click="hide_me">Close</button>
      </div>
      <div class="modal-content">
        <h3>Standard Weapons</h3>
        <div v-for="(weapon_group, index) in weapons.standard" :key=index class="skill-block">
          <h4>{{index}}</h4>
          <Weapon_Display_Component v-for="(weapon, index) in weapon_group" :key="index" :weapon_obj="weapon"
                                   @weapon_selected="add_new_weapon"/>
        </div>

        <h3>Sacred Weapon Artifacts</h3>
        <div v-for="(weapon_group, index) in weapons.sacred_weapons" :key=index class="skill-block">
          <h4>{{index}}</h4>
          <Weapon_Display_Component v-for="(weapon, index) in weapon_group" :key="index" :weapon_obj="weapon"
                                    @weapon_selected="add_new_weapon"/>
        </div>

        <h3>Etheria Weapons</h3>
        <Weapon_Display_Component v-for="(weapon, index) in etheria" :key="index" :weapon_obj="weapon"
                                  @weapon_selected="add_new_weapon"/>

      </div>
    </div>
  </div>
</template>

<script>
import weapons from '@/data/torn_moon_data/weapons.json'
import etheria from '@/data/torn_moon_data/etheria.json'
import Weapon_Display_Component from "@/components/Weapon_Display_Component.vue";

export default {
  props: {},
  components: {
    Weapon_Display_Component,
  },
  data() {
    return {
      weapons: weapons,
      etheria: etheria.weapons
    }
  },
  computed: {},
  methods: {
    hide_me(modal) {
      this.$emit('hide_' + modal + '_modal');
    },
    add_new_weapon(weapon_obj) {
      console.log('weapon has been selected: ', weapon_obj)
      this.$emit('weapon_selected', weapon_obj)
      this.hide_me('weapon')
    }
  },

};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal {
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 80%; /* Set a maximum height for the modal */
  max-width: 60%;
  overflow-y: auto; /* Enable vertical scrolling if content exceeds max height */
}

.modal-header {
//padding: 1rem 0; /* Adjust padding as needed */ position: sticky; top: 0;
  background-color: white; /* Match modal background color */
  z-index: 1; /* Ensure the header remains above the content */
}

.modal-content {
  /* You may need to add some padding or margin to the top and bottom of your content */
}

.close-button {
  margin-top: 1rem;
}

.skill-block {
  margin-bottom: 15px;
}
</style>
