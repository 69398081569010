import consts from '../../data/torn_moon_data/game_consts.json'
import {get_base_level} from "@/js/parsing_functions";

function tm_basic_roll(roll_obj){
    console.log('rolling ' + roll_obj.title)
    const roll = Math.floor(Math.random() * (consts.dice_ceiling - consts.dice_floor + 1)) + consts.dice_floor,
        cv = roll_obj.level - roll,
        success = roll <= roll_obj.level,
        class_label = success? 'success-text': 'failure-text'

    let log = `<div><span class="${class_label}">Rolled: ${roll}</span>. ${roll_obj.title} Level: ${roll_obj.level}, CV: ${cv}</div>`;
    let hash = 0

    if (roll === 1){
        hash = 2
        log += '- <span class="critical-success-text">Critical Success! Best outcome. 2 Hashmarks.</span><br>'
    }else if (roll === 12){
        hash = 1
        log += '- <span class="critical-fail-text">Critical Fail! 1 Hashmark.</span><br>'
    }

    log += success ? `<div class="success-text">Succeeded by ${cv}</div>` : `<div class="failure-text">Missed by ${-1 * cv}</div>`;

    return {
        roll: roll,
        cv: cv,
        log: log,
        hash: hash
    }
}

function tm_base_roll(attributes, skill_obj){
    console.log('rolling base for' + skill_obj.title)
    const target = get_base_level(attributes, skill_obj),
        roll = Math.floor(Math.random() * (consts.dice_ceiling - consts.dice_floor + 1)) + consts.dice_floor,
        cv = target - roll,
        success = roll <= target
    let log = "Roll for " + skill_obj.title + " untrained. Base (" + skill_obj.base + ") at half. Target level: " + target + ", Rolled: " + roll + "\n"
    let hash = 0

    if (roll === 1){
        hash = 2
        log += '- Untrained Best outcome. 2 Hashmarks.\n'
    }else if (roll === 12){
        hash = 1
        log += '- Critical Fail! 1 Hashmark.\n'
    }
    log += success ? "Succeeded by " + cv : "Missed by " + cv + "\n"

    return {
        roll: roll,
        cv: cv,
        log: log,
        hash: hash
    }
}

export {
    tm_basic_roll,
    tm_base_roll
}
