<template>
  <h3>Hit Points</h3>
  <p>
    Hit points determine the amount of physical damage a character can take before
    losing a limb, or being killed. The Points allocated are determined by the following chart
  </p>
  <p>
    The dominant bonus is applied to a characters dominant hand, right or left. Which hand is dominant can be chosen by
    the player, or determined at random as desired.
  </p>

  <table>
    <tr>
      <th>Race</th>
      <th>Head</th>
      <th>Chest</th>
      <th>Abdomen</th>
      <th>Legs</th>
      <th>Other</th>
      <th>Dominant Bonus</th>
    </tr>
    <tr v-for="(race_obj, index) in races" :key="index">
      <td>
        {{ race_obj.title }}
      </td>
      <td>
        {{ hp_calc[race_obj.name].head }}
      </td>
      <td>
        {{ hp_calc[race_obj.name].chest }}
      </td>
      <td>
        {{ hp_calc[race_obj.name].abdomen }}
      </td>
      <td>
        {{ hp_calc[race_obj.name].legs }}
      </td>
      <td>
        {{ hp_calc[race_obj.name].other }}
      </td>
      <td>
        {{ hp_calc[race_obj.name].dominant_bonus }}
      </td>
    </tr>
  </table>
</template>

<script>
import gen from "@/data/torn_moon_data/char_generation.json"
import races from "@/data/torn_moon_data/playable_races.json"

export default {

  name: 'TM_Char_Gen_HP',
  data() {
    return {
      hp_calc: gen.hp_calc,
      races: races
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
