<template>
  <div class="nav-box">
    <div class="char-gen-nav"
        v-for="(section, index) in sections"
        :key="index"
        @click="select_section(section, index)"
        :class="{ selected_subsection: selected_section === section.key }"
    >
      {{ section.title }}
    </div>
  </div>
  <hr>
  <div class="char-gen-content">
    <TM_Char_Gen_Intro_Component v-if="this.$store.state.view.section === 'intro'"></TM_Char_Gen_Intro_Component>
    <TM_Char_Gen_Attributes_Component v-if="this.$store.state.view.section === 'attributes'"></TM_Char_Gen_Attributes_Component>
    <TM_Char_Gen_Description_Component v-if="this.$store.state.view.section === 'desc'"></TM_Char_Gen_Description_Component>
    <TM_Char_Gen_Money_Component v-if="this.$store.state.view.section === 'money'"></TM_Char_Gen_Money_Component>
    <TM_Char_Gen_Movement_Component v-if="this.$store.state.view.section === 'movement'"></TM_Char_Gen_Movement_Component>
    <TM_Char_Gen_HP_Component v-if="this.$store.state.view.section === 'hp'"></TM_Char_Gen_HP_Component>
    <TM_Char_Gen_LF_Component v-if="this.$store.state.view.section === 'lf'"></TM_Char_Gen_LF_Component>
    <TM_Char_Gen_Unarmed_Damage_Component v-if="this.$store.state.view.section === 'unarmed_combat'"></TM_Char_Gen_Unarmed_Damage_Component>
    <TM_Char_Gen_Skills_Component v-if="this.$store.state.view.section === 'skills'"></TM_Char_Gen_Skills_Component>
    <TM_Char_Gen_SM_Skills_Component v-if="this.$store.state.view.section === 'sm_skills'"></TM_Char_Gen_SM_Skills_Component>
  </div>

  <br>
  <hr>
  <div class="nav-box">
    <span v-if="index !== 0" @click="select_section(sections[index - 1], index - 1)">⇦ Previous Step</span>
    &nbsp;&nbsp;&nbsp;
    <span v-if="index < sections.length - 1" @click="select_section(sections[index + 1], index + 1)">Next Step ⇨</span>
  </div>
</template>

<script>
import TM_Char_Gen_Intro_Component from "@/components/torn_moon/docs_site/char_gen/TM_Char_Gen_Intro_Component.vue";
import TM_Char_Gen_Attributes_Component
  from "@/components/torn_moon/docs_site/char_gen/TM_Char_Gen_Attributes_Component.vue";
import TM_Char_Gen_Description_Component
  from "@/components/torn_moon/docs_site/char_gen/TM_Char_Gen_Description_Component.vue";
import TM_Char_Gen_Money_Component from "@/components/torn_moon/docs_site/char_gen/TM_Char_Gen_Money_Component.vue";
import TM_Char_Gen_Movement_Component
  from "@/components/torn_moon/docs_site/char_gen/TM_Char_Gen_Movement_Component.vue";
import TM_Char_Gen_HP_Component from "@/components/torn_moon/docs_site/char_gen/TM_Char_Gen_HP_Component.vue";
import TM_Char_Gen_LF_Component from "@/components/torn_moon/docs_site/char_gen/TM_Char_Gen_LF_Component.vue";
import TM_Char_Gen_Unarmed_Damage_Component
  from "@/components/torn_moon/docs_site/char_gen/TM_Char_Gen_Unarmed_Damage_Component.vue";
import TM_Char_Gen_Skills_Component from "@/components/torn_moon/docs_site/char_gen/TM_Char_Gen_Skills_Component.vue";
import TM_Char_Gen_SM_Skills_Component
  from "@/components/torn_moon/docs_site/char_gen/TM_Char_Gen_SM_Skills_Component.vue";

export default {
  name: 'Character_Gen_Page_Component',
  components: {
    TM_Char_Gen_Intro_Component,
    TM_Char_Gen_Attributes_Component,
    TM_Char_Gen_Description_Component,
    TM_Char_Gen_Money_Component,
    TM_Char_Gen_Movement_Component,
    TM_Char_Gen_HP_Component,
    TM_Char_Gen_LF_Component,
    TM_Char_Gen_Unarmed_Damage_Component,
    TM_Char_Gen_Skills_Component,
    TM_Char_Gen_SM_Skills_Component
  },
  data() {
    return {
      sections: [
        {key: 'intro', title: 'Introduction'},
        {key: 'attributes', title: 'Attributes'},
        {key: 'desc', title: 'Description'},
        {key: 'money', title: 'Wealth'},
        {key: 'movement', title: 'Movement'},
        {key: 'hp', title: 'Hit Points'},
        {key: 'lf', title: "Life Force"},
        {key: 'unarmed_combat',title: 'Unarmed Combat Damage'},
        {key: 'skills', title: 'Skills'},
        {key: 'sm_skills', title: 'Sphere-Maker Skills'}
      ],
      selected_section: 'intro',
      index: 0
    };
  },
  mounted(){
    console.log('char gen got mounted')
    this.select_section(this.sections[0], 0)
  },
  methods: {
    select_section(section, index) {
      console.log('setting the selected section as: ', section)
      this.$store.commit("set_section", section.key)
      this.selected_section = section.key;
      console.log('setting selected index as: ', index)
      this.index = index
    }
  }
};
</script>

<style scoped>

.nav-box {
  display: flex;
  justify-content: center;
}
.char-gen-nav {
  font-family: "Georgia";
  font-size: larger;
  margin-right: 15px;
}

.char-gen-content {
  text-align: left;
}

.selected_subsection {
  color: forestgreen;
}

:deep(.basic_table) {
  border: 1px solid black;
  border-collapse: collapse;
}

:deep(.basic_table th) {
  border-bottom: 1px solid black;
  padding: 8px;
  text-align: left;
}

:deep(.basic_table td) {
  border: 1px solid darkslategray;
  padding: 4px;
  text-align: left;
}

:deep(.basic_table th) {
  background-color: #f2f2f2;
}
</style>
