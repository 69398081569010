<template>
  <h3>Skills</h3>

  <p>
    Calculation for the number of skill points available at character creation is as follows:
  </p>

  <ul>
    <li v-for="(val, index) in skill_points" :key="index">{{ index }}: {{ val }}</li>
  </ul>

  <p>
    General skills are purchased for {{ cost.purchase }} skill points each. The number of skill points that are
    available
    is determined by the Exp and Tho attributes as described above.
  </p>

  <p>
    Skills are listed with the formula to determine the starting skill level. Fractions are rounded to the
    nearest whole number. A chart that explains how to raise skills from the starting skill level can be
    found under the Skill Level heading, following the skill charts.
  </p>

  <p>Additional skill points may be spent to raise the skill level after purchase. The cost to raise increases as the
    level increases as follows:</p>

  <ul>
    <li v-for="(value, index) in cost.raise_to" :key="index">Raise from {{ parseInt(index) - 1 }} to {{ index }}:
      {{ value }}
    </li>
  </ul>

  <p>
    Skills can also be raised through game play either through instruction or through accumulating "hash marks"
    following successes and fails.
  </p>

  <p>Any remaining unspent skill points at the end of character creation can be converted to hash marks and applied
    either to untrained skills, or to trained skills as desired. Each skill point is converted into
    {{ cost.hash_mark_conversion }} hash marks.</p>


  <div class="skill_grid" v-for="(skill_class_list, index) in skills" :key="index">
    <h4>{{index}}</h4>
    <table class="basic_table">
      <tr>
        <th>
          Skill Title
        </th>
        <th>
          Base Attribute
        </th>
        <th>
          Description
        </th>
        <th>
          Level Formula
        </th>
        <th v-if="skill_class_list[0].limitation">
          Racial Limitation
        </th>
        <th v-else>
          Racial Bonus
        </th>

      </tr>
      <tr v-for="(skill_obj, index) in skill_class_list" :key="index">
        <td>{{skill_obj.title || '~'}}</td>
        <td>{{skill_obj.base || '~'}}</td>
        <td>{{skill_obj.description || '~'}}</td>
        <td>{{skill_obj.formula || '~'}}</td>
        <th v-if="skill_obj.limitation">
          {{get_race_obj_by_name(skill_obj.limitation).title || '~'}}
        </th>
        <td v-else>
          <span v-if="skill_obj.bonus.length">
            <span v-for="(bonus, index) in skill_obj.bonus" :key="index">
            {{
                get_race_obj_by_name(bonus.race).abbreviation

              }} {{bonus.type}} = {{bonus.value}}</span>
          </span>

        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import gen from "@/data/torn_moon_data/char_generation.json"
import skills from "@/data/torn_moon_data/skills.json"
import races from "@/data/torn_moon_data/skills.json"
import {get_race_obj_by_name} from "@/js/torn_moon/torn_moon_parsing_functions";

export default {
  name: 'TM_Char_Gen_Skills',
  data() {
    return {
      skill_points: gen.skill_points_calc,
      cost: gen.skill_point_cost,
      skills: skills,
      races: races,
    }
  },
  computed: {

  },
  methods: {
    get_race_obj_by_name: get_race_obj_by_name
  },
};
</script>

<style scoped>

</style>
