<template>
  <div class="modal-overlay">
    <div class="modal">
      <div class="modal-header">
        <button class="close-button" @click="hide_me('skill')">Close</button>
      </div>
      <div class="modal-content">
        <div v-for="(skill_list, index) in skills" :key=index class="skill-block">
          <h4>{{index}} Skills</h4>
          <Skill_Display_Component v-for="skill in skill_list" :key="skill.title" :skill_obj="skill"
                                   @skill_selected="add_new_skill"/>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import skills from '@/data/torn_moon_data/skills.json'
import Skill_Display_Component from "@/components/Skill_Display_Component.vue";

export default {
  props: {},
  components: {
    Skill_Display_Component,
  },
  data() {
    return {
      skills: skills
    }
  },
  computed: {},
  methods: {
    hide_me(modal) {
      this.$emit('hide_' + modal + '_modal');
    },
    add_new_skill(skill_obj) {
      console.log('skill has been selected: ', skill_obj)
      this.$emit('skill_selected', skill_obj)
      this.hide_me('skill')
    }
  },

};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal {
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 80%; /* Set a maximum height for the modal */
  max-width: 60%;
  overflow-y: auto; /* Enable vertical scrolling if content exceeds max height */
}

.modal-header {
//padding: 1rem 0; /* Adjust padding as needed */ position: sticky; top: 0;
  background-color: white; /* Match modal background color */
  z-index: 1; /* Ensure the header remains above the content */
}

.modal-content {
  /* You may need to add some padding or margin to the top and bottom of your content */
}

.close-button {
  margin-top: 1rem;
}

.skill-block {
  margin-bottom: 15px;
}
</style>
