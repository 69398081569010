<template>
  <h3>Wealth</h3>
  <p>
    The currency of Torn Moon is the chip. The parents left behind billions of these tiny circular wafers. Nearly
    all of the sacred machines the parents left behind require them to function. They pulse with faint glowing light when a
    power source is applied to them. The power sources themselves contain these chips. As such, the children prize them
    and exchange them with each other instead of bartering directly for goods and services.
  </p>
  <p>Unless otherwise indicated by the game master, each character must purchase all the goods they have in the world at
    the time of character creation. Any chips left over may be carried in to game play.</p>

  <p> The amount of chips a character has to purchase goods with is determined as follows:</p>

  <table class="basic_table">
    <tr>
      <th>Wealth Attribute Level</th>
      <th>Wealth Formula</th>
    </tr>
    <tr v-for="(wealth_obj, index) in wealth" :key="index">
      <td>{{index}}</td>
      <td>{{wealth_obj}}}</td>
    </tr>
  </table>
</template>

<script>
import gen from "@/data/torn_moon_data/char_generation.json"

export default {
  name: 'TM_Char_Gen_Money',
  data() {
    return {
      wealth: gen.wealth_allocation
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
