<template>
  <h3>Maps</h3>
  <div>
    <img src="@/assets/torn_moon/images/maps/world_map.jpg" alt="world map" class="map_image"/>
  </div>
  <div >
    <img src="@/assets/torn_moon/images/maps/terrain.jpg" alt="terrain map" class="map_image"/>
  </div>
  <div >
    <img src="@/assets/torn_moon/images/maps/clear_map.jpg" alt="terrain map" class="map_image"/>
  </div>

</template>

<script>

export default {
  name: 'TM_Story_Maps_Component',
  data() {
    return {}
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
.map_image {
  max-width: 1000px;
  max-height: 1000px;
}

</style>
