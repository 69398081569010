<template>
  <Skill_Grid_Modal_Component v-if="modal.skill_modal"
                              @hide_skill_modal="modal.skill_modal=false"
                              @skill_selected="add_skill"></Skill_Grid_Modal_Component>
  <Weapon_Grid_Modal_Component v-if="modal.weapon_modal"
                               @hide_weapon_modal="modal.weapon_modal=false"
                               @weapon_selected="add_weapon"></Weapon_Grid_Modal_Component>
  <div>
    <div :class="{nav_open: nav_visible, nav_closed: !nav_visible}">
      <div class="logbox" ref="log_ref">
        <p v-for="(item, index) in log" :key="index" v-html="item"></p>
      </div>
    </div>
    <div class="log_spacer"></div>
    <button @click="log=[]">Clear Log</button>
    <button @click="print_char">Print</button>
    <button v-if="!char.char_id && user.user_id && char.name" @click="insert_tm_char">Save {{ char.name }} to the
      Nexus
    </button>
    <div class="char_data">
      <button v-if="visible.descrip" @click="char.description.push(get_deep_copy(default_desc))">{{ add_more }}
        Description
      </button>
      <div class="section-header">
        <h2 @click="toggleVisibility('descrip')">
          <span v-if="visible.descrip">{{ collapse }}</span> <span v-else>{{ expand }}</span>
          {{ char.name || 'Unnamed Character' }} - Description
        </h2>
      </div>
      <div v-if="visible.descrip" class="section-header">
        <label for="name" class="spaced_3">Name</label>
        <input type="text" class="spaced_3" id="name" v-model="char.name"/>
        Player: {{ user.alias }}
      </div>
      <div v-for="(desc_obj, index) in char.description" :key="index">
        <div>
          <Description_Component v-if="visible.descrip" v-model="char.description[index]"
                                 @append_log="catch_append_log"
                                 @delete_desc="char.description.splice(index,1); catch_append_log(JSON.stringify(char.description))"/>
        </div>
      </div>
    </div>

    <div class="char_data">
      <button v-if="visible.movement" @click="char.data.movement.push(get_deep_copy(default_movement))">{{ add_more }}
        Movement Type
      </button>
      <div class="section-header">
        <h2 @click="toggleVisibility('movement')">
          <span v-if="visible.movement">{{ collapse }}</span> <span v-else>{{ expand }}</span>
          Movement
        </h2>
      </div>
      <div v-if="visible.movement" class="accordian_block">
        <div v-for="(move_obj, index) in char.data.movement" :key="index">
          <div>
            <Torn_Moon_Move_Component v-model="char.data.movement[index]"
                                      @append_log="catch_append_log"
                                      @delete_movement="char.data.movement.splice(index, 1)"/>
          </div>
        </div>
      </div>
    </div>

    <div class="char_data">
      <div class="section-header">
        <h2 @click="toggleVisibility('attributes')">
          <span v-if="visible.attributes">{{ collapse }}</span> <span v-else>{{ expand }}</span>
          Attributes
        </h2>
      </div>
      <div v-if="visible.attributes" class="accordian_block">
        <div v-for="(attribute, index) in char.data.attributes" :key="index">
          <template v-if="attribute.type === 'attribute'">
            <Attribute_Component v-model="char.data.attributes[index]"
                                 @append_log="catch_append_log"/>
          </template>
        </div>
      </div>
      <div class="section-header">
        <h2 v-if="visible.attributes">
          Senses
        </h2>
      </div>
      <div v-if="visible.attributes" class="accordian_block">
        <div v-for="(sense, index) in char.data.attributes" :key="index">
          <template v-if="sense.type === 'sense'">
            <Attribute_Component v-model="char.data.attributes[index]"
                                 @append_log="catch_append_log"/>
          </template>
        </div>
      </div>
    </div>

    <div class="char_data">
      <button v-if="visible.t_skills || visible.u_skills" id="new_skill" @click="show_modal('skill_modal')"
              class="add_more_button spaced_3">{{ add_more }}
        Skill
      </button>
      <div class="section-header">
        <h2 @click="toggleVisibility('t_skills')">
          <span v-if="visible.t_skills">{{ collapse }}</span> <span v-else>{{ expand }}</span>
          Trained Skills
        </h2>
      </div>
      <div v-if="visible.t_skills" class="accordian_block">
        <div v-for="(skill, index) in char.data.skills" :key="skill.title">
          <template v-if="skill.level > 0">
            <Skill_Component v-model="char.data.skills[index]" :attributes="char.data.attributes"
                             @skill_delete="char.data.skills.splice(index, 1)"
                             @append_log="catch_append_log"/>
          </template>
        </div>
      </div>

      <div class="section-header">
        <h2 @click="toggleVisibility('u_skills')">
          <span v-if="visible.u_skills">{{ collapse }}</span> <span v-else>{{ expand }}</span>

          Untrained Skills

        </h2>
      </div>
      <div v-if="visible.u_skills" class="accordian_block">
        <div v-for="(skill, index) in char.data.skills" :key="index">
          <template v-if="skill.level === 0">
            <Skill_Component v-model="char.data.skills[index]" :attributes="char.data.attributes"
                             @append_log="catch_append_log"
                             @skill_delete="char.data.skills.splice(index, 1); catch_append_log(JSON.stringify(skill) + ' deleted from skills\n')"/>
          </template>
        </div>
      </div>
    </div>

    <div class="char_data">
      <button v-if="visible.hp" @click="char.data.hit_points.push(get_deep_copy(default_hp))">{{ add_more }} Hit
        Locations
      </button>
      <button v-if="visible.hp" id="new_armor">{{ add_more }} Armor</button>
      <div class="section-header">
        <h2 @click="toggleVisibility('hp')">
          <span v-if="visible.hp">{{ collapse }}</span> <span v-else>{{ expand }}</span>
          Hit Points
        </h2>
      </div>
      <div v-if="visible.hp" class="accordian_block">
        <div v-for="(hp_obj, index) in char.data.hit_points" :key="index">
          <Torn_Moon_HP_Component v-model="char.data.hit_points[index]"
                                  @append_log="catch_append_log"
                                  @hp_delete="char.data.hit_points.splice(index, 1); catch_append_log(JSON.stringify(hp_obj) + ' deleted from hp\n')"/>
        </div>
        <Torn_Moon_LF_Component v-model="char.data.life_force" @append_log="catch_append_log"></Torn_Moon_LF_Component>
      </div>
    </div>

    <div class="char_data">
      <button v-if="visible.weapons" id="new_weapon" @click="show_modal('weapon_modal')">{{ add_more }} Weapon</button>
      <div class="section-header">
        <h2 @click="toggleVisibility('weapons')">

          <span v-if="visible.weapons">{{ collapse }}</span> <span v-else>{{ expand }}</span>
          Weapons
        </h2>
      </div>
      <div v-if="visible.weapons" class="accordian_block">
        <div v-for="value in char.data.weapons" :key="value.id">
          <Torn_Moon_Weapon_Component :weapon_obj="value" :skills="char.data.skills"
                                      :attributes="char.data.attributes" @append_log="catch_append_log"/>
        </div>
      </div>
    </div>

    <div class="char_data">
      <button v-if="visible.ammo" id="new_ammo">{{ add_more }} Ammo</button>
      <div class="section-header">
        <h2 @click="toggleVisibility('ammo')">

          <span v-if="visible.ammo">{{ collapse }}</span> <span v-else>{{ expand }}</span>
          Ammunition

        </h2>
      </div>
      <div v-if="visible.ammo" class="accordian_block">
        <div v-for="value in char.data.ammuntion" :key="value.id">
          <Torn_Moon_Ammunition_Component :ammo_obj="value" :weapons="char.data.weapons"/>
        </div>
      </div>
    </div>

    <div class="char_data">
      <button v-if="visible.cells" id="new_cell">{{ add_more }} Power Cells</button>
      <div class="section-header">
        <h2 @click="toggleVisibility('cells')">

          <span v-if="visible.cells">{{ collapse }}</span> <span v-else>{{ expand }}</span>
          Power Cells


        </h2>
      </div>
      <div v-if="visible.cells" class="accordian_block">
        <div v-for="value in char.data.power_cells" :key="value.id">
          <Torn_Moon_Power_Cell_Component :cell_obj="value" :weapons="char.data.weapons"/>
        </div>
      </div>
    </div>

    <div class="char_data">
      <button v-if="visible.etheria" id="new_etheria">{{ add_more }} Etheria</button>
      <div class="section-header">
        <h2 @click="toggleVisibility('etheria')">

          <span v-if="visible.etheria">{{ collapse }}</span> <span v-else>{{ expand }}</span>
          Etheria

        </h2>
      </div>
      <div v-if="visible.etheria" class="accordian_block">
        <div v-for="value in char.data.etheria" :key="value.id">
          <Torn_Moon_Etheria_Component :etheria_obj="value" :attributes="char.data.attributes"/>
        </div>
      </div>
    </div>

    <div class="char_data">
      <button v-if="visible.eq" id="new_eq">{{ add_more }} Equipment</button>
      <div class="section-header">
        <h2 @click="toggleVisibility('eq')">

          <span v-if="visible.eq">{{ collapse }}</span> <span v-else>{{ expand }}</span>
          Equipment

        </h2>
      </div>
      <div v-if="visible.eq" class="accordian_block">
        <div v-for="value in char.data.equipment" :key="value.id">
          <Torn_Moon_Equipment_Component :eq_obj="value"/>
        </div>
      </div>
    </div>

    <div class="char_data">
      <button v-if="visible.notes" @click="char.data.notes.push(get_deep_copy(default_note))">{{ add_more }} Notes
      </button>
      <div class="section-header">
        <h2 @click="toggleVisibility('notes')">
          <span v-if="visible.notes">{{ collapse }}</span> <span v-else>{{ expand }}</span>
          Notes
        </h2>
      </div>
      <div v-if="visible.notes" class="accordian_block">
        <div v-for="(note_obj, index) in char.data.notes" :key="index">
          <Notes_Component v-model="char.data.notes[index]"
                           @append_log="catch_append_log"
                           @delete_note="char.data.notes.splice(index, 1); catch_append_log('Note ' + JSON.stringify(note_obj) + ' deleted')"/>
        </div>
      </div>
    </div>

    <div v-if="last_sync">
      Last Sync to Nexus: {{ last_sync }} Automatic sync every {{ this.CONSTS.sync_delay / 1000 / 60 }} minutes.
    </div>
    <div v-if="this.char.char_id">
      <button @click="sync">Sync To Nexus Now</button>
    </div>


  </div>
</template>

<script>
import Attribute_Component from "@/components/Attribute_Component.vue";
import Skill_Component from "@/components/Skill_Component.vue";
import Torn_Moon_HP_Component from "@/components/torn_moon/Torn_Moon_HP_Component.vue";
import Torn_Moon_LF_Component from "@/components/torn_moon/Torn_Moon_LF_Component.vue";
import Torn_Moon_Weapon_Component from "@/components/torn_moon/Torn_Moon_Weapon_Component.vue";
import Torn_Moon_Ammunition_Component from "@/components/torn_moon/Torn_Moon_Ammunition_Component.vue";
import Torn_Moon_Power_Cell_Component from "@/components/torn_moon/Torn_Moon_Power_Cell_Component.vue";
import Torn_Moon_Etheria_Component from "@/components/torn_moon/Torn_Moon_Etheria_Component.vue";
import Torn_Moon_Equipment_Component from "@/components/torn_moon/Torn_Moon_Equipment_Component.vue";
import Notes_Component from "@/components/Notes_Component.vue";
import Skill_Grid_Modal_Component from "@/components/Skill_Grid_Modal_Component.vue";
import Weapon_Grid_Modal_Component from "@/components/Weapon_Grid_Modal_Component.vue";
import Torn_Moon_Move_Component from "@/components/torn_moon/Torn_Moon_Move_Component.vue";
import Description_Component from "@/components/Description_Component.vue";
import {build_char_skeleton} from "@/js/torn_moon/skeletons";
import CONSTS from "@/data/consts";
import index from "vuex";
import default_movement from "@/data/torn_moon_data/default_movement.json"
import default_desc from "@/data/torn_moon_data/default_description.json"
import default_hp from "@/data/torn_moon_data/default_hp.json"
import default_note from "@/data/torn_moon_data/default_note.json"
import {db_insert_character, db_update_character} from "@/js/db_ops"
import {get_hash} from "@/js/parsing_functions"

export default {
  name: "Torn_Moon_Sheet.vue",
  computed: {
    index() {
      return index
    },
    user() {
      return this.$store.state.user || {'alias': 'empty_test'}
    },
    nav_visible() {
      return this.$store.state.nav_visible
    },
    char() {
      let selected = this.$store.state.selected.char
      if (selected) {
        console.log(`using the character set in the store: ${selected.name} ${selected.char_id}`)
      } else {
        console.log('setting a new character skeleton')
        selected = build_char_skeleton()
      }
      return selected
    }
  },
  props: [''],
  components: {
    Attribute_Component,
    Description_Component,
    Skill_Component,
    Torn_Moon_HP_Component,
    Torn_Moon_LF_Component,
    Torn_Moon_Weapon_Component,
    Torn_Moon_Ammunition_Component,
    Torn_Moon_Power_Cell_Component,
    Torn_Moon_Etheria_Component,
    Torn_Moon_Equipment_Component,
    Notes_Component,
    Skill_Grid_Modal_Component,
    Weapon_Grid_Modal_Component,
    Torn_Moon_Move_Component
  },
  mounted() {
    console.log('torn moon sheet view is mounted')
    this.$store.commit("set_page", 'torn moon sheet')

    console.log('torn moon sheet mounted, sync delay is set to: ' + CONSTS.sync_delay)
    window.selected = this.char

    function sync_me(context) {
      if (context.$store.state.selected.char.char_id) {
        console.log('calling sync')
        context.sync()
      } else {
        console.log('character has not yet been inserted, waiting until next cycle')
      }
      console.log("sleeping " + JSON.stringify(CONSTS.sync_delay) + " before next sync")
      setTimeout(() => {
        sync_me(context)
      }, CONSTS.sync_delay)
    }

    console.log('starting the sync timer at ', new Date())
    setTimeout(() => sync_me(this), CONSTS.sync_delay)
  },
  data() {
    return {
      CONSTS: CONSTS,
      default_desc: default_desc.new,
      default_movement: default_movement.new,
      default_hp: default_hp.new,
      default_note: default_note.new,
      collapse: "⋀",//"⬆",
      expand: "⋁",// "⬇",
      add_more: "＋", //"➕",
      log: [],
      modal: {
        skill_modal: false,
        weapon_modal: false
      },
      char_attribute_style: {
        width: '150px'
      },
      visible: {
        descrip: true,
        movement: true,
        attributes: true,
        senses: true,
        t_skills: true,
        u_skills: true,
        hp: true,
        lf: true,
        weapons: true,
        ammo: true,
        cells: true,
        etheria: true,
        eq: true,
        notes: true,
        spacer: false,
      },
      last_sync: null,
      last_error: null,
    };
  },
  watch: {},
  methods: {
    db_insert_character,
    toggleVisibility(toggle_id) {
      console.log('toggling accordian bar for ', toggle_id)
      this.visible[toggle_id] = !this.visible[toggle_id];
      console.log(toggle_id + ' is now ', this.visible[toggle_id])
    },
    updateFormField() {
      console.log('update form field called')
    },
    catch_append_log(event) {
      console.log('append log caught an event: ', event)
      this.log.push(event)
      this.$nextTick(() => {
        const log_ref = this.$refs.log_ref;
        log_ref.scrollTop = log_ref.scrollHeight;
      });
    },
    show_modal(modal) {
      console.log('popping up the modal: ', modal)
      this.modal[modal] = true
      console.log(modal + ' is now: ', this.modal[modal])
    },
    add_skill(skill_obj) {
      console.log('adding a new selected skill: ', skill_obj)
      console.log("currently skills are: ", JSON.stringify(this.char.data.skills))
      this.char.data.skills.push(skill_obj)
      console.log("skills are now: ", this.char.data.skills)
    },
    add_weapon(weapon_obj) {
      console.log('adding a new selected weapon: ', weapon_obj)
      console.log("currently weapons are: ", JSON.stringify(this.char.data.weapons))
      this.char.data.weapons.push(weapon_obj)
      console.log("weapons are now: ", this.char.data.weapons)
    },
    get_deep_copy(object) {
      let copy = JSON.parse(JSON.stringify(object))
      if (copy.session) {
        copy.session = new Date()
      }
      if (copy.date) {
        copy.date = new Date()
      }
      return copy
    },
    async sync() {
      console.log(`Syncing ${this.char.name} to The Nexus.`)
      const hash = await get_hash(JSON.stringify(this.char))
      console.log(`new hash: ${hash}`)
      console.log(`old hash: ${this.$store.state.char_hash}`)
      if (this.$store.state.char_hash !== hash) {
        console.log("char has been updated since last sync.  syncing.")
        const success = await db_update_character(this.user.user_id, this.$store.state.token, this.$store.state.selected.game.name, this.char)
        console.log('character updated? ', success)
        this.$store.commit('set_char_hash', hash)
        this.last_sync = new Date()
        console.log("sync completed at: ", this.last_sync)
        this.catch_append_log(`Something about ${this.char.name} has changed.  The Golem calls them to The Nexus.`)
      } else {
        console.log(`no change detected for ${this.char.name} sleeping until next check`)
      }
    },
    async insert_tm_char() {
      const char_id = await db_insert_character(this.$store.state.user.user_id, this.$store.state.token, 'torn moon', this.char)
      console.log("character saved successfully.  returned new char_id: ", char_id)
      this.char.char_id = char_id
      this.catch_append_log(this.char.name + ' has been saved to The Nexus.')
    },
    print() {
      console.log('this does nothing at the moment')
    }

  },

}


</script>

<style scoped>

.nav_open {
  position: fixed;
  top: 215px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
//z-index: 999;
}

.nav_closed {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
//z-index: 999;
}

.log_spacer {
  width: 100%;
  height: 130px;
}

.logbox {
  border: 1px solid black;
  width: 50%;
  height: 130px;
  background-color: ghostwhite;
  overflow: auto;
  white-space: pre-wrap;
}

textarea {
  width: 100%;
  height: 100px;
  resize: vertical;
}

:deep(.standard_short_input ) {
  max-width: 35px;
}

:deep(.standard_medium_input) {
  max-width: 45px;
}

:deep(.spaced_3) {
  margin-left: 3px;
  margin-right: 3px;
}

:deep(.section-header) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.add_more_button) {
  margin-left: 6px;
}

:deep(.critical-success-text) {
  color: green;
  font-weight: bold;
}

:deep(.critical-fail-text) {
  color: deeppink;
  font-weight: bold;
}

:deep(.success-text) {
  color: blue;
}

:deep( .failure-text) {
  color: red;
}

.char_data {
  border: 1px solid black;
  padding: 2px;
  margin: 4px;
}

h2 {
//text-align: center; font-size: larger;
}
</style>
