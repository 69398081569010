<template>
  <h3>General Equipment</h3>
  <p>
    All of the equipment listed here is used generally by all races. No availability roll is neccesary to aquire any of
    these items.
  </p>

  <table class="basic_table">
    <tr>
      <th>
        Item Name
      </th>
      <th>Description</th>
      <th>Price</th>
    </tr>
    <tr v-for="(eq_obj, index) in equipment" :key="index">
      <td>{{eq_obj.title}}</td>
      <td>{{eq_obj.description}}</td>
      <td>{{eq_obj.price}}</td>
    </tr>
  </table>


</template>

<script>
import equipment from "@/data/torn_moon_data/equipment.json"

export default {
  name: 'TM_Char_LF_Attributes',
  data() {
    return {
      equipment: equipment.standard
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
