const server = "https://api.golemindustrial.com"
const server_port = 443
const sync_delay = 300000 //5 min in miliseconds
const games = {
  torn_moon: 'Torn Moon',
  avalon: 'Avalon',
  yahron: 'Yahron'
}

export default {
  server,
  server_port,
  games,
  sync_delay
};
