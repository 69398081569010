<template>
  <div class="ammo_block">
    <div>
      <span class="ammo_title">{{ ammo_obj.title }}</span>&nbsp;
      Type: <span class="ammo_title">({{ ammo_obj.type }})</span>
      Amount: <input type="number" :value="ammo_obj.amount"
                     @change="updateFormField(key, $event.target.value)"/>
      <template v-if="ammo_obj.effect">
        Effects:
        <div v-for="obj in ammo_obj.effect" :key="obj.id">
          {{ obj }}
        </div>
      </template>

      Equipped: true/false
      <button>Equip</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['ammo_obj', 'weapons'],
  computed: {},
  methods: {},
};
</script>

<style scoped>
.ammo_block {
  border: 1px solid #ccc;
}
</style>
