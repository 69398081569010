<template>
  <h3>Sphere Maker Specific Skills</h3>


  <p>The special skills listed in this section are available to Sphere Makers only; under no circumstance may
    they be wielded by any other race.
  </p>

  <p>
    All sphere makers gain the Restore ability without cost as outlined below:
  </p>


  <p>
    <span class="innate_skill">Restore: Formula (Rel+Con+Tho)3 Must be done while meditating.</span>
  </p>
  <p>
    <span class="innate_skill">Description: This meditative process allows the Maker to regenerate
    lost life force at an increased rate. Modifiers will be
    applied for distractions. Requires 30 minutes to gain the full benefit, shorter sessions may have modifiers applied. LF restored is equal to the CV+Con Level.</span>
  </p>

  <h4>Etheria</h4>
  <p>
    Sphere makers do not typically use the technology left behind by the parents. They consider them sacred property of
    the parents and not for their own use. Instead, Makers have learned to infuse their life force into every day
    objects
    granting them additional properties. Items enhanced in this way are called Etherial. Anyone can handle or use these
    items, regardless of race, but only Makers can invoke their special abilities by channeling a bit of their own life
    force into the object to awaken its special properites.
  </p>

  <p>
    If the etherial item requires a special skill to use, the life force used to activate the special properties of that
    item is equal to the skill roll. Otherwise, a thought roll will be substituted. Normal failures do not incur a
    penalty. However, critical failures do still cost the full 12 Life Force of the failed roll.
  </p>

  <p>
    For example, if a Maker opts to use an Etherial bow like the Comet, she will declare to the game master that she is
    using invoking it, and roll the Bow skill. If the roll is successful, she will remove Life Force equal to the roll
    of the bow skill, and the additional effects of the Comet will be invoked. She may also choose to use the bow as a
    normal short bow, and in that case no life force would be expended, and the Comet would not ggrant any additional
    effects.
  </p>

  <h4>Ethos </h4>
  <p>
    For a Sphere Maker to use his Ethos, the Maker must follow a series of steps.
  </p>
  <p>
    Sphere Makers can sense within their own essence a series of partitions that they refer to as cells. A sphere Maker
    has a number of cells equal to their religion attribute.
  </p>
  <p>
    Through meditation a Maker can siphon off a portion of their own life force, and train it to fulfill some other
    task. The
    siphoning process renders the life force no longer able to sustain the Maker any longer, at this point the life
    force is called "Vapor". To successfully siphon, train, and store the vapor, the Maker must meditate for a period of
    time. Typically this amount of time in minutes is equal to (12 Religion + 1) per cell, though that amount of time may
    vary based on the environment and the physical and mental condition of the meditator. The Maker rolls the Etheria
    skill from
    the table below. In many cases, decisions about the capabilities of the vapor must be made at the time it infused.
    It is important to note on the the character sheet any details about the vapor training made at the infuse step.
  </p>
  <p>
    Cells can be stored indefinitely so long as the Maker lives. Trained vapor may be expelled at will to execute its
    new purpose. <span class="attention"> Expelling Ethos costs 1d12 Life Force </span> .
  </p>
  <p>
    Failure to successfully infuse vapor still removes the life force from the Maker and the maker loses control of the
    vapor and it is expelled from their essence. This vapor is detectable by other Makers up to a number of yards equal
    to the Makers Religion attribute.
  </p>

  <p> Vapor is a vile substance that seems to take on a life of its own. No one is certain where it goes or what it does
    once dispelled. Even non Makers have
    commented at times on the unsettling air in a room when vapor has been discharged
  </p>

  <p>
    Ethos differs from other skills in that it can not be learned by trial and error. The secrets of Etheria have been
    handed down through the Religious orders since the Makers first began to record their history. Etheria may be
    learned at character creation, or through instruction only. Until the correct number of hash marks are aquired, only
    vapor may be formed. No fully socketed Ethos.
  </p>

  <p>
    Makers with a Religeon attribute of 1-4 must choose only one Discipline to select skills from. Those whose
    attribute is 5-9 may select 2, 10-12 may choose 3. The only way to increase the number of disciplines available is
    to increase the Religion attribute.
  </p>

  <p>
    <span class="attention">These skills are purchased at a cost of {{ skill_costs.sm_purchase }} skill
      points each, </span> rather than
    the {{ skill_costs.purchase }} required for General Skills. They are increased in level at the same rate shown in
    the "Skills" section.
  </p>

  <h4>Ethos Disciplines</h4>
  <hr>
  <div v-for="(skill_list, index) in ethos" :key=index class="skill-block">
    <h4>{{ index }} Discipline</h4>
    <table class="basic_table">
      <tr>
        <th>Ethos Title</th>
        <th>Method of Use</th>
        <th>Forumula</th>
        <th>Description</th>
      </tr>
      <tr v-for="(skill_obj, index) in skill_list" :key=index>
        <td>{{ skill_obj.title }}</td>
        <td>{{ skill_obj.method }}</td>
        <td>{{ skill_obj.formula }}</td>
        <td>{{ skill_obj.description }}</td>
      </tr>
    </table>
  </div>

</template>

<script>
import gen from "@/data/torn_moon_data/char_generation.json"
import ethos from "@/data/torn_moon_data/ethos.json"

export default {
  name: 'TM_Char_Gen_SM_Skills',
  components: {},
  data() {
    return {
      skill_costs: gen.skill_point_cost,
      ethos: ethos
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

.innate_skill {
  font-weight: bold;
}

.attention {
  color: crimson;
}

</style>
