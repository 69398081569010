<template>
  <h3>Rules</h3>

</template>

<script>

export default {
  name: 'Docs_Rules_Component',
  data() {
    return {
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
