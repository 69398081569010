<template>
  <h3>Standard Armor</h3>
  <p>
    All of the equipment listed here is used generally by all races. No availability roll is neccesary to aquire any of
    these items.
  </p>

  <p>
    Armor allows protection from various types of damage by deflecting or absorbing a certain
    amount of the damage that might otherwise be inflicted. The amount of damage absorbed is
    indicated as the Armor Rating. Armor can deflect most attacks from weapons that have a Penetration rating of less
    than the armor Penetration rating.
  </p>

  <p>
    If an attacker scores a critical success it is understood to have hit a chink in the armor, thus
    bypassing it.
  </p>

  <p>
    Weapons that exceed the Penetration of armor do equal damage to the armor as well as to the wearer of
    the armor.
  </p>

  <p>
    When armor is destroyed it must be replaced, or repaired by either a qualified professional, or a
    character with the appropriate skills involved.
  </p>


  <div v-for="(category, index) in armor" :key="index">
    <h4>{{ index }} Armor</h4>
    <table class="basic_table">
      <tr>
        <th>Body Location</th>
        <th>Availability</th>
        <th>Penetration</th>
        <th>Armor Rating</th>
        <th>Hit Points</th>
        <th>Price</th>
        <th>Description</th>
      </tr>
      <tr v-for="(eq_obj, index) in category" :key="index">
        <td>{{ eq_obj.location }}</td>
        <td>{{ eq_obj.availability || "all" }}</td>
        <td>{{ eq_obj.penetration || "N/A" }}</td>
        <td>{{ eq_obj.armor_rating || "N/A" }}</td>
        <td>{{ eq_obj.hit_points || "N/A" }}</td>
        <td>{{ eq_obj.price || "N/A" }}</td>
        <td>{{ eq_obj.description || "N/A" }}</td>
      </tr>
    </table>
  </div>

  <h4>Shields</h4>
  <p>
    Shields allow a player who opts for a one handed weapon to increase his defensive capabilities
    by carrying a shield in his off hand. Shields increase defensive CR by the Shield Factor (SF). On
    critical success defensive CR rolls, the shield wielder may counter attack outside of his turn.
    Shield is a CR against the opponent’s weapon skill, and replaces the weapon skill CR for
    defense.
  </p>
  <p>
    Shields (except bucklers) may be used to defend against bow, crossbow, sling, and thrown
    weapons. Defending against these attacks is a CR against the attacker, unlike normal combat;
    many different ranged combatants may be defended against in a single phase provided the shield
    bearer does not move in that phase and the attackers are located in a 45 degree arc in front of
    the shield. Players wishing to create a phalanx may do so; this will add +1 to the shield skill of all
    characters in the phalanx per phalanx participant up to a maximum of +4.
  </p>
  <p>
    Shields do not affectively defend against weapons that exceed their Penetration Rating (P) and
    will take damage equal to the damage the defender wielding the shield takes if he chooses to use
    his shield skill rather than attempting to deflect the weapon with the appropriate combat skill.
  </p>

  <table class="basic_table">
    <tr>
      <th>Shield Type</th>
      <th>Availability</th>
      <th>Penetration</th>
      <th>Shield Rating</th>
      <th>Hit Points</th>
      <th>Price</th>
      <th>Description</th>

    </tr>
    <tr v-for="(eq_obj, index) in shields" :key="index">
      <td>{{ eq_obj.title }}</td>
      <td>{{ eq_obj.availability || "all" }}</td>
      <td>{{ eq_obj.penetration || "N/A" }}</td>
      <td>{{ eq_obj.shield_rating || "N/A" }}</td>
      <td>{{ eq_obj.hit_points || "N/A" }}</td>
      <td>{{ eq_obj.price || "N/A" }}</td>
      <td>{{ eq_obj.description || "N/A" }}</td>
    </tr>
  </table>


</template>

<script>
import armor from "@/data/torn_moon_data/armor.json"

export default {
  name: 'TM_Char_LF_Attributes',
  data() {
    return {
      armor: armor.standard,
      shields: armor.shields
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
