<template>
  <h3>Power Management</h3>
  <p>
    All of the equipment listed here involves Parent technology, typically these items would not be used by Sphere
    Makers.
  </p>

  <h3>Power Cells</h3>
  <div v-for="(cell, index) in cells" :key="index">
    <h4>{{ index }}</h4>
    <table class="basic_table">
      <tr>
        <th>Connector Type</th>
        <th>Power Capacity</th>
        <th>Price</th>
      </tr>
      <tr v-for="(eq_obj, index) in cell" :key="index">
        <td>{{ index }}</td>
        <td>{{ eq_obj.power }}</td>
        <td>{{ eq_obj.price }}</td>
      </tr>
    </table>
  </div>

  <h3>Cell Chargers</h3>
  <table class="basic_table">
    <tr>
      <th>Charger Type</th>
      <th>Availability</th>
      <th>Power Output</th>
      <th>Total Power Cell Connections</th>
      <th>Price</th>
      <th>Description</th>
    </tr>
    <tr v-for="(eq_obj, index) in chargers" :key="index">
      <td>{{ eq_obj.title }}</td>
      <td>{{ eq_obj.availability }}</td>
      <td>{{ eq_obj.power_output }}</td>
      <td> {{ eq_obj.cell_connections }}</td>
      <td>{{ eq_obj.price }}</td>
      <td>{{eq_obj.description}}</td>
    </tr>
  </table>



</template>

<script>
import power from "@/data/torn_moon_data/power_cells.json"

export default {
  name: 'TM_EQ_Sacred',
  data() {
    return {
      cells: power.power_cells,
      chargers: power.chargers
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
