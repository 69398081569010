import {createStore} from 'vuex';

const store = createStore({
    state: {
        user: null,
        token: null,
        nav_visible: true,
        char_hash: null,
        selected: {
            game: null,
            char: null,
        },
        view: {
            page: '',
            sub_page: '',
            section: '',
        }
    },
    mutations: {
        //call a mutation by this.$store.commit(mutation, param)
        set_user(state, user) {
            console.log('set user called with:', user);
            state.user = user;
        },
        set_token(state, token) {
            state.token = token
        },
        set_char_hash(state, hash) {
            console.log('set_char_hash called with: ', hash)
            state.char_hash = hash
        },
        set_characters(state, char_list) {
            console.log('adding ' + char_list.length + " characters to " + state.user.alias)
            state.user.characters = char_list
        },
        set_game(state, game) {
            console.log('store is setting the selected game as: ', game)
            state.selected.game = game;
        },
        set_character(state, character) {
            console.log('store is setting the selected character as: ', character)
            state.selected.char = character
        },
        set_nav_visible(state, visible) {
            console.log('store is setting the nav visible state as: ', visible)
            state.nav_visible = visible
        },
        set_page(state, page) {
            console.log('setting the view page state to ', page)
            state.view.page = page
        },
        set_subpage(state, subpage) {
            console.log('setting the view subpage state to ', subpage)
            state.view.sub_page = subpage
        },
        set_section(state, section){
            console.log('setting the view section state to ', section)
            state.view.section = section
        }

    },
    actions: {},
    getters: {
        user: (state) => {
            return state.user;
        },
        token: (state) => {
            return state.token;
        },
        selected_char: (state) => {
            return state.selected.char;
        },
        all_chars: (state) => {
            return state.user.characters
        },
        selected_game: (state) => {
            return state.selected.game;
        },
    },
});

export default store;

