<template>
  <div>
    <h1>The Golem offers you these worlds to select from.</h1>
    <Game_Select_Component></Game_Select_Component>
  </div>
  <div v-if="game">
    <h1>In The Nexus, The world of {{game.title}} holds these that belong to {{user.alias}}</h1>
    <Character_Grid_Component :selected_game="game" />
  </div>
</template>

<script>

import Game_Select_Component from "@/components/Game_Select_Component";
import Character_Grid_Component from "@/components/Character_Grid_Component";

export default {
  name: 'Characters_View',
  components: {
    Character_Grid_Component,
    Game_Select_Component
  },
  computed: {
    game() {
      return this.$store.state.selected.game
    },
    user(){
      return this.$store.state.user
    }
  },
  data() {
    return {
    };
  },
  methods: {

  },
  mounted() {
    console.log('character view is mounted')
    this.$store.commit("set_page", 'character')
  },
};
</script>

<style>
/* Your component styles */
</style>
