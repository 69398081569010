<template>
  <div class="attribute_block">
    <span class="attribute_title">{{ modelValue.title }} ({{modelValue.abbr}})</span>
    Level <input type="number" v-model="attribute.level" class="standard_short_input spaced_3"
           @change="$emit('append_log', attribute.title + ' level changed to ' + $event.target.value + '\n')"/>
    Hash Marks <input type="number" v-model="attribute.hash" class="standard_short_input spaced_3"
                 @change="$emit('append_log', attribute.title + ' hashes changed to ' + $event.target.value + '\n')"/>
    <button @click="roll(attribute)" class="spaced_3">🎲</button>
  </div>
</template>

<script>
import {tm_basic_roll} from "@/js/torn_moon/roll_functions";
export default {
  props: ['modelValue'],
  computed: {
    attribute(){
      return this.modelValue
    }
  },
  methods: {
    roll(){
      const roll = tm_basic_roll(this.attribute)
      console.log('got back: ', roll)
      if(roll.hash){
        this.attribute.hash += roll.hash
      }
      this.$emit('append_log', roll.log)
    }
  },
};
</script>

<style scoped>
.attribute_block {
  padding: 5px;
  text-align: center;
}
</style>
