<template>
  <div class="notes_block">
    <div>
      Title: <input type="text" v-model="note_obj.title"
                    @change="$emit('append_log', 'Note Title is now ' + $event.target.value)"/>
      Session: {{ note_obj.session }}
    </div>
    <div>
      <textarea v-model="note_obj.content" @change="$emit('append_log', note_obj.title + ' updated content')"></textarea>
    </div>
    <div>
      <button @click="$emit('edit_note', note_obj)">
        Edit
      </button>
      <button @click="$emit('delete_note', note_obj)">Delete</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['modelValue'],
  data() {

  },
  computed: {
    note_obj() {
      return this.modelValue
    },
  },
  methods: {},
};
</script>

<style scoped>
.notes_block {
  border: 1px solid #ccc;
}
</style>
