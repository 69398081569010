<template>
  <div class="tm-content">
    <Docs_Character_Gen_Page_Component v-if="this.$store.state.view.sub_page === 'char_gen'"></Docs_Character_Gen_Page_Component>
  </div>
  <div class="tm-content">
    <Docs_Equipment_Component v-if="this.$store.state.view.sub_page === 'equipment'"></Docs_Equipment_Component>
  </div>
  <div class="tm-content">
    <Docs_References_Component v-if="this.$store.state.view.sub_page === 'ref'"></Docs_References_Component>
  </div>
  <div class="tm-content">
    <Docs_Printables_Component v-if="this.$store.state.view.sub_page === 'printables'"></Docs_Printables_Component>
  </div>
  <div class="tm-content">
    <Docs_Gallery_Component v-if="this.$store.state.view.sub_page === 'gallery'"></Docs_Gallery_Component>
  </div>
  <div class="tm-content">
    <Docs_Rules_Component v-if="this.$store.state.view.sub_page === 'rules'"></Docs_Rules_Component>
  </div>
  <div class="tm-content">
    <Docs_Story_Component v-if="this.$store.state.view.sub_page === 'story'"></Docs_Story_Component>
  </div>

</template>

<script>
//nav has to be set in the app cuz i suck at css
import Docs_Character_Gen_Page_Component from "@/components/torn_moon/docs_site/Docs_Char_Gen_Page_Component.vue";
import Docs_Equipment_Component from "@/components/torn_moon/docs_site/Docs_Equipment_Component.vue";
import Docs_References_Component from "@/components/torn_moon/docs_site/Docs_References_Component.vue";
import Docs_Printables_Component from "@/components/torn_moon/docs_site/Docs_Printables_Component.vue";
import Docs_Rules_Component from "@/components/torn_moon/docs_site/Docs_Rules_Component.vue";
import Docs_Story_Component from "@/components/torn_moon/docs_site/Docs_Story_Component.vue";
import Docs_Gallery_Component from "@/components/torn_moon/docs_site/Docs_Gallery_Component.vue";

export default {
  name: 'Torn_Moon_View',
  components: {
    Docs_Character_Gen_Page_Component,
    Docs_Equipment_Component,
    Docs_References_Component,
    Docs_Printables_Component,
    Docs_Rules_Component,
    Docs_Story_Component,
    Docs_Gallery_Component
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted() {
    console.log('torn moon view is mounted')
    this.$store.commit("set_page", 'torn moon')
  },
  methods: {

  },
};
</script>

<style>
.tm-content {
  width: 70%;
  margin: 0 auto; /* Center horizontally */
  text-align: center;
}


</style>
