<template>
  <div>
    <form @submit.prevent="login">
      <label for="username">Username:</label>
      <input type="text" id="username" v-model="username" required>
      <br>
      <label for="password">Password:</label>
      <input type="password" id="password" v-model="password" required>
      <br>
      <button type="submit">Login</button>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </form>
  </div>
</template>

<script>

import {db_login} from "@/js/db_ops";

export default {
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
    };
  },
  methods: {
    async login() {
      let result = await db_login(this.username, this.password, this)
      if (result){
        console.log('login pulled a result: ', result)
        console.log('login component setting authentication: ', result.user)
        this.$store.commit('set_user', result.user)
        this.$store.commit('set_token', result.token)
        this.$router.push({ name: 'Characters' })
      } else {
        console.log('error problem')
        this.errorMessage = "NO result from server."
      }
    },
  },
};
</script>

<style scoped>
h1 {
  margin-bottom: 1em;
}
form {
  display: flex;
  flex-direction: column;
}
label {
  margin-bottom: 0.5em;
}
input {
  padding: 0.5em;
  margin-bottom: 1em;
}
button {
  padding: 0.5em 1em;
}
.error-message {
  color: red;
  margin-top: 0.5em;
}
</style>
