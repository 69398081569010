<template>
  <h3>Quick Reference</h3>

  <h4>Hit Location</h4>
  <div v-for="(table, index) in consts.hit_locations" :key="index">
    <h4>{{table.title}} - {{table.die}}</h4>
    <table class="basic_table">
      <tr>
        <th>Die Roll</th>
        <th>Location</th>
      </tr>
      <tr v-for="(val, key) in table.rolls" :key="key">
        <td>{{key}}</td>
        <td>{{val}}</td>
      </tr>
    </table>
  </div>

  <table>
    <tr>
      <th>Condition</th>
      <th>Modifier</th>
      <th>Duration</th>
    </tr>
    <tr v-for="(obj, index) in consts.modifiers" :key="index">
      <td>{{obj.condition}}</td>
      <td>{{obj.modifier}}</td>
      <td>{{obj.duration}}</td>
    </tr>
  </table>




</template>

<script>
import consts from "@/data/torn_moon_data/game_consts.json"

export default {
  name: 'TM_Char_LF_Attributes',
  data() {
    return {
      consts: consts
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
