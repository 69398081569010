<template>
  <div class="nav-box">
    <div class="story-nav"
         v-for="(section, index) in sections"
         :key="index"
         @click="select_section(section, index)"
         :class="{ selected_subsection: selected_section === section.key }"
    >
      {{ section.title }}
    </div>
  </div>
  <hr>
  <div class="story-content">
    <TM_Story_Races_Component v-if="this.$store.state.view.section === 'races'"></TM_Story_Races_Component>
    <TM_Story_Ranks_Component v-if="this.$store.state.view.section === 'ranks'"></TM_Story_Ranks_Component>
    <TM_Story_Maps_Component v-if="this.$store.state.view.section === 'maps'"></TM_Story_Maps_Component>
    <TM_Story_Shorts_Component v-if="this.$store.state.view.section === 'shorts'"></TM_Story_Shorts_Component>
  </div>

  <br>
  <hr>
</template>

<script>
import TM_Story_Races_Component from "@/components/torn_moon/docs_site/story/TM_Story_Races_Component.vue";
import TM_Story_Ranks_Component from "@/components/torn_moon/docs_site/story/TM_Story_Ranks_Component.vue";
import TM_Story_Maps_Component from "@/components/torn_moon/docs_site/story/TM_Story_Maps_Component.vue";
import TM_Story_Shorts_Component from "@/components/torn_moon/docs_site/story/TM_Story_Shorts_Component.vue";

export default {
  name: 'Docs_Story_Component',
  components: {
    TM_Story_Races_Component,
    TM_Story_Ranks_Component,
    TM_Story_Maps_Component,
    TM_Story_Shorts_Component
  },
  data() {
    return {
      sections: [
        {key: 'races', title: 'Races of the Children'},
        {key: 'ranks', title: 'Ranks of the Religion'},
        {key: 'maps', title: 'Maps'},
        {key: 'apostates', title: 'Apostate Factions'},
        {key: 'shorts', title: 'Shorts'}
      ],
      selected_section: 'races',
      index: 0
    };
  },
  mounted() {
    console.log('story got mounted')
    this.select_section(this.sections[0], 0)
  },
  methods: {
    select_section(section, index) {
      console.log('setting the selected section as: ', section)
      this.$store.commit("set_section", section.key)
      this.selected_section = section.key;
      console.log('setting selected index as: ', index)
      this.index = index
    }
  }
};
</script>

<style scoped>

.nav-box {
  display: flex;
  justify-content: center;
}

.story-nav {
  font-family: "Georgia";
  font-size: larger;
  margin-right: 15px;
}

:deep(.basic_table) {
  border: 1px solid black;
  border-collapse: collapse;
}

:deep(.basic_table th) {
  border-bottom: 1px solid black;
  padding: 8px;
  text-align: left;
}

:deep(.basic_table td) {
  border: 1px solid darkslategray;
  padding: 4px;
  text-align: left;
}

:deep(.basic_table th) {
  background-color: #f2f2f2;
}

.char-gen-content {
  text-align: left;
}

.selected_subsection {
  color: forestgreen;
}
</style>
