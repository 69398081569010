<template>
  <h4 class="lf_block">Life Force</h4>
  <div class="lf_block">

    Maximum <input type="number" v-model="lf_obj.max" class="standard_medium_input"
                   @change="$emit('append_log', 'Life Force Max value is now ' + $event.target.value)"/>
    Current <input type="number" v-model="lf_obj.current"  class="standard_medium_input"
                   @change="$emit('append_log', 'Life Force Current value is now ' + $event.target.value)"/>
  </div>
</template>

<script>
export default {
  props: ['modelValue'],
  computed: {
    lf_obj() {
      return this.modelValue
    },
  },
  methods: {},
};
</script>

<style scoped>
.lf_block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.lf_block > * {
  margin: 0 10px; /* Adjust the value as needed */
}
</style>
