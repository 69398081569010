<template>
  <h3>Character Attributes</h3>
  <p>Each character has attributes and senses which will determine how well they can accomplish
    physical feats, as well as influencing their inherent ability to accomplish certain skills. </p>

  <p>
    There are two types of attributes, Core Attributes, typically called simply 'attributes' and Sense Attributes,
    typically called 'senses'.
  </p>

  <h4>Senses</h4>

  <p>
    Senses are the basic senses of Hearing, Sight, Smell, and Touch. Taste is omitted as being inconsequential to game
    play. However, as the majority of taste is actually derived from smell in the brain, smell may be substitutied for
    taste if the situation were to arise where a character might need to detect the taste of something.
  </p>

  <p>
    Each race has a different total of points to spend on senses, as well as different inherent bonuses to those senses.
  </p>

  <p>
    Each level of a sense costs one sense point. The minimum sense level allowed is 0, indicating that the sense with a
    level of 0 is completely inoperative for that character, rendering them blind or deaf for example. The maximum
    allowed level of a sense is 12.
  </p>

  <p>The total number of points each race has to spend on senses is as follows:</p>

  <ul>
    <li v-for="(race_obj, index) in races" :key="index">
      {{ race_obj.title }}: {{ point_allocation[race_obj.name].sense_points }}
    </li>
  </ul>

  <p>
    Sense bonuses are as follows:
  </p>

  <table class="basic_table">
    <tr>
      <th>Race</th>
      <th>Hearing (he)</th>
      <th>Sight (si)</th>
      <th>Smell (sm)</th>
      <th>Touch (to)</th>
    </tr>
    <tr v-for="(race_obj, index) in races" :key="index">
      <td>{{ race_obj.title }}</td>
      <td>{{ point_allocation[race_obj.name].bonuses.he || 0 }}</td>
      <td>{{ point_allocation[race_obj.name].bonuses.si || 0 }}</td>
      <td>{{ point_allocation[race_obj.name].bonuses.sm || 0 }}</td>
      <td>{{ point_allocation[race_obj.name].bonuses.to || 0 }}</td>
    </tr>
  </table>

  <h4>Attributes</h4>
  <p>
    Attributes describe a character physically, socially, and mentally. A detailed description of the attributes in Torn
    Moon can be read <a href="#core_desc">here.</a>
  </p>

  <p>
    Attributes, like senses are purchased from a point pool based on the following allocations.
  </p>
  <ul>
    <li v-for="(race_obj, index) in races" :key="index">
      {{ race_obj.title }}: {{ point_allocation[race_obj.name].attribute_points }}
    </li>
  </ul>

  <p>
    Unlike senses however, some core attribute costs vary.
  </p>

  <p>
    Costs are detailed on the following chart. Points allotted for attributes can not
    be transferred to other abilities, senses or locations.
  </p>

  <table class="basic_table">
    <tr>
      <th>Attribute</th>
      <th>Attribute Point Cost</th>
      <th>Per Attribute Point</th>
    </tr>
    <tr v-for="(attrib, index) in core()" :key="index">
      <td>{{ attrib.title }} ({{ attrib.abbreviation }})</td>
      <td>{{ point_cost[attrib.abbreviation] }}</td>
      <td>1</td>
    </tr>
  </table>

  <p>
    Attribute racial bonuses are as follows:
  </p>

  <table class="basic_table">
    <tr>
      <th>Race</th>
      <th>Charisma (cha)</th>
      <th>Constitution (con)</th>
      <th>Coordination (coor)</th>
      <th>Experience (exp)</th>
      <th>Religion (rel)</th>
      <th>Speed (sp)</th>
      <th>Strength (str)</th>
      <th>Thought (tho)</th>
      <th>Wealth (we)</th>
    </tr>
    <tr v-for="(race_obj, index) in races" :key="index">
      <td>{{ race_obj.title }}</td>
      <td>{{ point_allocation[race_obj.name].bonuses.cha || 0 }}</td>
      <td>{{ point_allocation[race_obj.name].bonuses.con || 0 }}</td>
      <td>{{ point_allocation[race_obj.name].bonuses.coor || 0 }}</td>
      <td>{{ point_allocation[race_obj.name].bonuses.exp || 0 }}</td>
      <td>{{ point_allocation[race_obj.name].bonuses.rel || 0 }}</td>
      <td>{{ point_allocation[race_obj.name].bonuses.sp || 0 }}</td>
      <td>{{ point_allocation[race_obj.name].bonuses.str || 0 }}</td>
      <td>{{ point_allocation[race_obj.name].bonuses.tho || 0 }}</td>
      <td>{{ point_allocation[race_obj.name].bonuses.we || 0 }}</td>
    </tr>
  </table>

  <h4 id="core_desc">Core Attribute Descriptions</h4>
  <h5>Charisma:</h5>
  <p>
    Charisma designates a characters overall ability to interact with other people. His
    poise, presence, charm, ability to convince, coerce, or win others to his way of thinking.
    Additionally Charisma denotes a characters physical appearance and relative attractiveness.
    Charisma is a determining factor in age, as well as all social based skills.
  </p>
  <h5>Constitution:</h5>
  <p>
    Constitution represents the physical makeup of a character, especially with
    respect to the health, strength, and overall condition of their body. Constitution is the primary
    contributor to a character’s Hit Points, Life Force, Weight, Load, Wound Rating, and overall ability
    to withstand damage or sustain activity.
  </p>
  <h5>Coordination:</h5>
  <p>
    Coordination shows a character’s readiness and grace in the execution of
    physical activity. Coordination is the primary contributor to most combat skills, and a major
    contributor to many physical skills.
  </p>
  <h5>
    Experience:
  </h5>
  <p>
    Experience denotes practical knowledge, skill, or practice derived from direct
    observation of or participation in events or in particular activities over the course of a characters
    life. Experience is the primary factor governing the amount of skills a character may have learned
    over the course of their lives, and can be a factor contributing to the overall skill level of the
    character as well. During the course of game play, experience can also indicate a characters
    exposure to different cultural events and activities, famous persons, laws, geographical areas,
    etc.
  </p>
   <h5>Religion:</h5>
  <p>
    Religion is an indicator of how involved a character is in the Religion of the Parents, the
    dominating political movement in the world. It influences how much a character might know of
    the use and workings of various artifacts left behind by the Parents in their ascension. As a
    statistic, Religion contributes to a characters ability to use artifacts of both Parentical and Ethos
    origin. Additionally, as it reflects political involvement and connection, Religion can influence the
    availability of Parentical artifacts by lending a modifier to availability rolls. A Religion attribute
    level of 7-9 gives a +1 modifier to availability, 10-11 =+2, 12= +3.
  </p>
  <h5>Speed:</h5>
  <p>
    Speed designates how quickly a character is capable of moving. This attribute
    contributes to a characters Movement stat, which denotes how far they can move in a phase, as
    well as how well they can execute tasks that require rapid movement. Speed is a contributor in
    many physical combat skills, as well as some athletic skills. Additionally a characters speed is
    the major contributing factor determining the order in which combat occurs during a combat
    phase by way of the initiative roll.
  </p>
   <h5>
     Strength:
   </h5>
  <p> Strength represents a characters capacity for physical exertion and as such it affects
    most physical skills. It also affects the characters ability to move large objects, and fight. Strength
    contributes to the number of damage points a character can sustain, and contributes to the load
    that a character is able to carry for long periods of time.
  </p>

  <h5>Thought:</h5>
  <p>
    Thought represents a character’s intelligence; her ability to learn, understand, deal
    with new or trying situations, to reason, her ability to apply knowledge to manipulate her
    environment, and to think abstractly. Thought influences a characters technical prowess, the
    amount of learning they have gained from their experience by way of skill points at character
    creation, and in the case of Sphere Makers, influences the amount of Life Force they have
    available.
  </p>

  <h5>
    Wealth:
  </h5>
  <p>
    Wealth is an indication of the amount of goods a character has acquired over the course
    of their life, though inexplicably they seem to be carrying this equipment at all times and in all
    places they may be traveling. For the purposes of game play, wealth is the primary factor
    determining the number of chips (the currency of the world) a character has to purchase goods at
    the time of character creation, as well as the amount of chips they are carrying at the time the
    game commences.
  </p>
</template>

<script>
// import attributes from "@/data/torn_moon_data/attributes.json"
import char_gen from "@/data/torn_moon_data/char_generation.json"
import races from "@/data/torn_moon_data/playable_races.json"
import attributes from "@/data/torn_moon_data/attributes.json"

export default {
  name: 'TM_Char_Gen_Attributes',
  components: {},
  data() {
    return {
      point_allocation: char_gen.point_allocation,
      point_cost: char_gen.point_cost,
      races: races,
      attributes: attributes,
      core: () => {
        let rv = []
        attributes.forEach((attribute) => {
          if (attribute.type === "attribute") {
            rv.push(attribute)
          }
        })
        return rv
      }
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
