<template>
  <h3>Sacred Artifact Armor</h3>
  <p>
    Artifact Armor is not available for Sphere Maker (K) Use unless they have a negative Religion
    (Rel) value as demonstrated in the Apostate Character Generation Rules section of the Character
    Generation document.
  </p>

  <p>
    Artifact armor typically defends against specific types of damage, unless otherwise noted,
    critically successful attacks against an opponent wearing Artifact Armor do not find a “chink” and
    score full damage.
  </p>

  <p>
    Distribution of Etherial items and weapons is tightly controlled, as such purchasing these items at character generation time
    requires a succesful availability roll.
  </p>


    <h4>Artifact Armor</h4>
    <table class="basic_table">
      <tr>
        <th>Armor Type</th>
        <th>Availability</th>
        <th>Armor Rating</th>
        <th>Damage Type Mitigated</th>
        <th>Price</th>
        <th>Description</th>
      </tr>
      <tr v-for="(eq_obj, index) in armor" :key="index">
        <td>{{ eq_obj.title }}</td>
        <td>{{ eq_obj.availability || "all" }}</td>
        <td>{{ eq_obj.armor_rating || "N/A" }}</td>
        <td>{{ eq_obj.dmg_mitigation_type || "N/A" }}</td>
        <td>{{ eq_obj.price || "N/A" }}</td>
        <td>{{ eq_obj.description || "N/A" }}</td>
      </tr>
    </table>

  <h4>Artifact Shields</h4>
  <table class="basic_table">
    <tr>
      <th>Shield Type</th>
      <th>Availability</th>
      <th>Penetration</th>
      <th>Shield Rating</th>
      <th>Hit Points</th>
      <th>Price</th>
      <th>Description</th>
      <th>Power Use</th>
      <th>Connector Type</th>
    </tr>
    <tr v-for="(eq_obj, index) in shields" :key="index">
      <td>{{ eq_obj.title }}</td>
      <td>{{ eq_obj.availability || "all" }}</td>
      <td>{{ eq_obj.penetration || "N/A" }}</td>
      <td>{{ eq_obj.shield_rating || "N/A" }}</td>
      <td>{{ eq_obj.hit_points || "N/A" }}</td>
      <td>{{ eq_obj.price || "N/A" }}</td>
      <td>{{ eq_obj.description || "N/A" }}</td>
      <th>{{ eq_obj.power_use  || "N/A" }}</th>
      <th>{{ eq_obj.connector || "N/A" }}</th>
    </tr>
  </table>


</template>

<script>
import armor from "@/data/torn_moon_data/armor.json"

export default {
  name: 'TM_EQ_Sacred_Armor',
  data() {
    return {
      armor: armor.artifact_armor,
      shields: armor.artifact_shields
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
