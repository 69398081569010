<template>
  <Navigation_Component />
  <Torn_Moon_Nav_Component v-if="page_state === 'torn moon'"/>
  <div id="app">
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Navigation_Component from "@/components/Navigation_Component.vue";
import Torn_Moon_Nav_Component from "@/components/torn_moon/Torn_Moon_Nav_Component.vue";

export default {
  name: 'App',
  components: {
    Navigation_Component,
    Torn_Moon_Nav_Component
  },
  data() {
    return {
      receivedValue: '',
    };
  },
  computed:{
    page_state(){
      return this.$store.state.view.page
    },
  },
  mounted(){

  },
  methods: {
    catch_authentication(payload){
      console.log('app caught authentication', payload)
    }
  },
}

;
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
}

.content {
  flex: 1;
  width: 100%;
  padding: 1rem;
}
</style>
