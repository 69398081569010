<template>
  <h3>Etheria</h3>
  <h3>Sacred Artifact Equipment</h3>
  <p>
    All of the items listed in this section are intended for Sphere Maker use only. There are many items that could be
    used by other races, but they would only be able to use them in their simplified form. For example, an Earth Mover
    may use the Comet with her "Bow" skill, but would be unable to unlock the potential of the Comet and so would not
    gain any of the additional benefits of the etherial nature of the weapon. This is also true for Sphere Makers, they
    may opt to use etherial weapons as only their mundane counterparts and not expend any life force if they so choose.
  </p>

  <p>
    Unless otherwise noted, all etheria requires the user to expend life force to activate the special properties of
    that item.
  </p>

  <p>
    Distribution of Etherial items and weapons is tightly controlled, as such purchasing these items at character generation time
    requires a succesful availability roll.
  </p>


  <h4>Weapons</h4>
    <table class="basic_table">
      <tr>
        <th>
          Weapon Name
        </th>
        <th>Description</th>
        <th>Price</th>
        <th>Availability</th>
        <th>Class</th>
        <th>Damage</th>
        <th>Damage Type</th>
        <th>Penetration</th>
        <th>Range</th>
        <th>Skill</th>
        <th>Life Force</th>
        <th>1 Handed/2 Handed</th>
      </tr>
      <tr v-for="(weapon, index) in weapons" :key="index">
        <td>{{ weapon.title }}</td>
        <td>{{ weapon.description }}</td>
        <td>{{ weapon.price || "N/A" }}</td>
        <td>{{ weapon.availability || "all" }}</td>
        <td>{{ weapon.class || "N/A" }}</td>
        <td>{{ weapon.damage || "N/A" }}</td>
        <td>{{ weapon.damage_type || "N/A" }}</td>
        <td>{{ weapon.penetration || "N/A" }}</td>
        <td>{{ weapon.range || "N/A" }}</td>
        <td>{{ weapon.skill || "N/A" }}</td>
        <td>{{ weapon.life_force || "N/A" }}</td>
        <td>{{ weapon.hands || "N/A" }}</td>
      </tr>
    </table>

  <h4>Ammunition</h4>
  <table class="basic_table">
    <tr>
      <th>
        Ammunition Name
      </th>
      <th>Description</th>
      <th>Price</th>
      <th>Availability</th>
      <th>Damage</th>
      <th>Damage Type</th>
      <th>Life Force</th>
    </tr>
    <tr v-for="(am, index) in ammo" :key="index">
      <td>{{ am.title }}</td>
      <td>{{ am.description }}</td>
      <td>{{ am.price || "N/A" }}</td>
      <td>{{ am.availability || "all" }}</td>
      <td>{{ am.damage || "N/A" }}</td>
      <td>{{ am.damage_type || "N/A" }}</td>
      <td>{{ am.life_force || "N/A" }}</td>
    </tr>
  </table>

  <h4>Armor</h4>
  <table class="basic_table">
    <tr>
      <th>
        Armor Name
      </th>
      <th>Description</th>
      <th>Price</th>
      <th>Availability</th>
      <th>Armor Rating</th>
      <th>Life Force</th>
    </tr>
    <tr v-for="(ar, index) in armor" :key="index">
      <td>{{ ar.title }}</td>
      <td>{{ ar.description }}</td>
      <td>{{ ar.price || "N/A" }}</td>
      <td>{{ ar.availability || "all" }}</td>
      <td>{{ ar.armor_rating || "N/A" }}</td>
      <td>{{ ar.life_force || "N/A" }}</td>
    </tr>
  </table>

  <h4>Shields</h4>
  <table class="basic_table">
    <tr>
      <th>
        Shield Name
      </th>
      <th>Description</th>
      <th>Price</th>
      <th>Availability</th>
      <th>Shield Rating</th>
      <th>Hit Points</th>
      <th>Life Force</th>
    </tr>
    <tr v-for="(shield, index) in shields" :key="index">
      <td>{{ shield.title }}</td>
      <td>{{ shield.description }}</td>
      <td>{{ shield.price || "N/A" }}</td>
      <td>{{ shield.availability || "all" }}</td>
      <td>{{ shield.shield_rating || "N/A" }}</td>
      <td>{{ shield.hit_points || 'N/A' }}</td>
      <td>{{ shield.life_force || "N/A" }}</td>
    </tr>
  </table>

  <h4>Equipment</h4>
  <table class="basic_table">
    <tr>
      <th>
        Equipment Name
      </th>
      <th>Description</th>
      <th>Price</th>
      <th>Availability</th>
      <th>Life Force</th>
    </tr>
    <tr v-for="(eq, index) in equipment" :key="index">
      <td>{{ eq.title }}</td>
      <td>{{ eq.description }}</td>
      <td>{{ eq.price || "N/A" }}</td>
      <td>{{ eq.availability || "all" }}</td>
      <td>{{ eq.life_force || "N/A" }}</td>
    </tr>
  </table>

  <h4>Transportation</h4>
  <table class="basic_table">
    <tr>
      <th>
        Transportation Name
      </th>
      <th>Description</th>
      <th>Price</th>
      <th>Availability</th>
      <th>Life Force</th>
    </tr>
    <tr v-for="(transport, index) in transportation" :key="index">
      <td>{{ transport.title }}</td>
      <td>{{ transport.description }}</td>
      <td>{{ transport.price || "N/A" }}</td>
      <td>{{ transport.availability || "all" }}</td>
      <td>{{ transport.life_force || "N/A" }}</td>
    </tr>
  </table>


</template>

<script>
import etheria from "@/data/torn_moon_data/etheria.json"

export default {
  name: 'TM_EQ_Etheria',
  data() {
    return {
      weapons: etheria.weapons,
      ammo: etheria.ammunition,
      armor: etheria.armor,
      equipment: etheria.equipment,
      transportation: etheria.transportation,
      shields: etheria.shields,
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
