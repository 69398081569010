<template>
  <nav class="tm_navigation">
    <div class="tm_link-bar"
         v-for="(subpage, index) in sub_pages"
         :key="index"
         @click="select_subpage(subpage, index)"
         :class="[{  selected_subpage: selected_subpage === subpage.key }, 'tm_nav-link']"
    >
      {{ subpage.title }}
    </div>
  </nav>
</template>

<script>

export default {
  name: 'Torn_Moon_Nav_Component',
  data() {
    return {
      isVisible: true,
      selected_subpage: 'char_gen',
      index: 0,
      sub_pages: [
        {key: 'char_gen', title: 'Character Generation'},
        {key: 'equipment', title: 'Equipment'},
        {key: 'ref', title: 'References'},
        {key: 'rules', title: 'Rules'},
        {key: 'printables', title: 'Printable Character Sheet'},
        {key: 'gallery', title: 'Gallery'},
        {key: 'story', title: 'Story'},
      ]
    };
  },
  computed: {
    // authenticated() {
    //
    // }
  },
  mounted(){
    console.log('char gen got mounted')
    this.select_subpage(this.sub_pages[0], 0)
  },
  methods: {
    toggleVisibility() {
      console.log('toggling nav bar to ', !this.isVisible)
      this.isVisible = !this.isVisible;
      this.$store.commit('set_nav_visible', this.isVisible)
    },
    select_subpage(subpage, index) {
      console.log('setting the selected subpage as: ', subpage)
      this.$store.commit("set_subpage", subpage.key)
      this.selected_subpage = subpage.key;
      console.log('setting selected index as: ', index)
      this.index = index
    }
  },
};
// window.nav_toggle = new CustomEvent('nav_toggle')
</script>

<style>
.tm_navigation {
  background-color: #88e23e;
  color: #050505;
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center;
  position: relative;
}

.tm_link-bar {
  font-family: 'tm_links', sans-serif;
  font-size: xx-large;
  display: flex; /* Display children in a row */
  flex-wrap: wrap; /* Allow wrapping if content overflows */
  justify-content: center; /* Center the children horizontally */
  margin-top: 1rem;
}

@font-face {
  font-family: 'tm_links';
  src: url('../../assets/Cherish-Regular.ttf') format('truetype');
}

.tm_nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}


a {
  color: #050505;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.nav-icon {
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.fa-bars {
  color: #050505;
  font-size: 1.5rem;
}

.left-icons {
  margin-bottom: auto;
  align-self: flex-start;
  padding-left: 3rem;
}

.bottom-right {
  margin-top: auto;
  align-self: flex-end;
  padding-right: 1rem;
}

.right {
  margin-top: auto;
  align-self: flex-end;
  padding-right: 1rem;
}

.selected_subpage {
  color: darkgreen;
}
</style>
