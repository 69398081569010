<template>
  <h3>Shorts</h3>

  <div class="section-header">
    <h2 @click="toggleVisibility('dathos_1')">Dathos Chapter 1</h2>
    <div v-if="visible.dathos_1">
      <p>
        It was either the smell or the crowds that made Gerit feel uniquely like Gerit.  Dathos had traveled to a good many towns and villages across the Eastern Expanse, no where else had he seen this many bodies packed into so few acres of land.  Shoulder to shoulder like pickled sliver fish in a jar they bumped down streets and through alleyways, shouting cursing, grunting, and stinking.
      </p>

      <p>
        The smell was a reek of too many unwashed bodies that blended in a kind of discord with the bouquet of cooking meats, punctuated by the fumes wafting up through sewage cisterns and grates. From time to time the smell of the cooking food would manage to be almost pleasant before the other combating odors fought their way to the surface.  He could tell he had been away too long as he choked against the waves of offending aromas.  In a day or two he would notice the smell less, it never completely went away but some how it did seem to move into the background of one's consciousness.
      </p>

      <p>
        Dothos kept his head low and his eyes moving as he elbowed his way through the throngs.  The Legionnaires were always easy to spot, the black armor and colored sashes signaled them to the wary crowd who somehow managed to make just enough room around them despite the pressing throngs of flesh.  He didn't expect any trouble from them, they seldom concerned themselves with people like Dathos, but it was good to steer clear of them when it could be helped.  They could be a problem even for honest folk when the mood struck them.
      </p>


      <p>
        It was The Grey he was on the lookout for.  They blended into the crowd, dissolving in the sea of bobbing heads.  It was a healthy habit to steer clear of any White as often as was possible as well though if he had attracted their attention he would likely be dead already.  No it was The Grey that were most likely to be a sticking point today and so he did his utmost to stay alert, without appearing to do so.  That meant staying mostly to the main thoroughfares.
      </p>

      <p>
        He was no stranger to the city, several of his formative years were spent here, learning what he called his "trade.""  He could pick his way through the back alleys and muddy streets as well as any of the natives but today he would stay on the muddy streets.  The alleys could sometimes be quicker, but they were seldom safer.  If you didn't get your throat slit or your pocket picked you were just as likely to take a beating from any of The Grey that felt like a bit of mischief that morning.
      </p>

      <p>
        He moved further south, away from The Citadel and the main trade district and the crowds lessened.  As the citizens spread out it gave Dathos more of a chance to pick out a few that he was nearly certain to be Grey. The younger Greys all had an attitude, an aloofness.  A sense of superiority.  The older Grey had it as well, but they had learned to hide it well enough to blend in with a crowd of "common" folk.  These two had to be in their first or second season on the streets.  The Dweller on the left leaned against a post with his arms crossed on his chest.  His tail twitched back and forth as he swept the street with his eyes.  His partner was a Walker.  He stood erect as a statue, arms at his sides listening and watching.  The Old Koad had once told Dathos even to be wary of the obvious ones such as these two.  He was convinced that the older Greys set them out in spots like these intentionally.  That way while the would-be trouble causers were focused on them, the more clever Greys could single them out and circle round on them.  Dathos was not sure about that, but it was always better to be cautious.  he kept his pace measured and moved on past.
      </p>

      <p>
        When he was past the young Greys, Dathos turned East into a wide ally that was flanked on the north and the south with small ramshackle shops.  Two Walkers stood on barrels halfway down the alley and shouted above the noise of the bustling alley, extolling the superior virtues of the red eel they had for sale. Other merchants worked the crowd as well but most stayed out of the sun behind simple counters and awnings. Dathos counted the store fronts until he arrived at a melon merchant, third window on his left.  He sidled up to the counter and began to inspect the sour melons on display.  Undoubtedly there were Grey spies in the milling congregation, and he had to act his part. He would lift a melon to his ear and shake it, turn it over in his hands and examine its rind for flaws.  he would lift it to his nose and sniff deeply and loudly, then set it in the pile and repeat the process with the next.
      </p>

      <p>
        The Melon Merchant stood behind the counter growling at an aging Mover woman.  This merchant was the only fat Maker Dathos had ever seen.  He leaned heavily on the counter, never making eye contact with his customer as she was insisting his sour melons were of poorer quality than the price he demanded would justify.  He dismissed her arguments without rebuttal and boasted the virtues of those same melons. Dathos had the feeling this was likely a regular exchange between these two.
      </p>

      <p>
        The merchant had only one wing, there was no evidence of the other, no visible stump, nor bulge beneath his simple yellow woven tunic. On the side of his face opposite the missing wing he wore a red leather patch over his eye and a scar marred his corpulant face that started at his hairline and ran under the eye patch to his lip.  Presently the merchant relented tossing his hands in the air and exclaiming that the old woman would drive him out of business.  The gleeful Mover tossed a pair of chips on the counter and shuffled down the alley with her now prized sour melon.
      </p>

      <p>
        His customer gone, the merchant turned his attention to Dathos, which is not to say he engaged him directly.  He turned his body a quarter so as to face in the direction that Dathos was inspecting melons, but his beady eyes looked out past puffy cheeks staring downward at nothing in particular.
      </p>

      <p>
        "Good morning to you Master Swimmer, now that the hag is gone can I interest you in the finest sour melon you will find in Gerit?"  As he spoke he gestured grandly to the counter filled with plump white and black orbs.  He spoke with a weariness that bordered on slurring his words together.  Were it not so early in the morning Dathos might have suspected the Maker to be intoxicated, or very near to it.
      </p>

      <p>
        "Good morning to you sir."  Dathos replied, rubbing his hands against the sides of his cloak wiping the juice of an over ripe melon away. He was careful to talk in quiet even tones.  He was very careful that he did not whisper or speak so quietly as to draw attention, but his words would barely be discernable at the next stall even if the street were to suddenly fall silent.  "Sour melon does not particularly interest me this early, I was hoping for something savory and I had heard that from time to time you had a crop of dust melon.  If you were to have such a thing, I would be interested in discussing a price with you."
      </p>

      <p>
        The melon man rubbed a finger along the under side of his nose as he spoke, seeming to address the counter of melons.  "I could have one in back here, come on in and we will see if we cant unearth one o' the ghastly things for you."  With his free hand he motioned to the door to the east side of the melon counter.
      </p>

      <p>
        With a few hasty steps Dathos skirted through the door and past a pile of leaking melons.  The merchant moved a pole, which dropped the wooden awning with a clap, sealing off the window and hiding his wares from the world.  As soon as the awning was secured, the rotund man marshaled Dathos through the interior door to the humble living quarters inside.
      </p>

      <p>
        "You fool eel lover!"  The merchant hissed between his teeth in a hoarse whisper, he moved with an animation that belied his girth and which had been totally absent in his previous dealings both with Dathos and the old Mover woman.  "I should chop you into fish bait and feed you to the canal biters!  Are you new at this?  You know there were at least three Greys in that alley?"  he turned his attention from Dathos to press his ear against the door.  "I have half a mind to put a smoking hole in you where you stand.  What have you got?  Be quick now."
      </p>

      <p>
        From under his cloak Dathos thrust out his hand and slowly opened his fingers displaying his prize.  Three small silver orbs each crisscrossed with lines that pulsed with a dim white glow.  The light seemed to start at one orb and as it crossed the surface of that orb it spread to the others in a wave, though their surfaces did not touch.
      </p>

      <p>
        The merchant held his breath for the briefest of seconds, betraying himself though he regained his composure nearly instantly.
      </p>

      <p>
        "And what exactly are those supposed to be?  Glow stones, you risked both of our lives for glow stones?  I would be lucky to trade all three of them for a handful of sway.  Take this if it will get rid of you and let me back to my customers."  He spat as he tossed four shimmering chips on the ground at Dathos' feet.
      </p>

      <p>
        Dathos withdrew his hand calmly, slowly returning the objects to the pouch concealed under his cloak.  "If you do not know what these are you must not be the melon man I have heard so much about.  These engrams have nineteen times the process line of any the Brown allows in circulation.  Sixty thousand each, right now, or I walk out of that door and you never see me again."
      </p>

      <p>
        The melon man choked and sputtered.  "Look boy, I don't know who you think I am, Adebojo descended again from the sky to shower you with..."
      </p>

      <p>
        Dathos cut him off.  "Have it your way."  He said flatly, taking a step toward the door.
      </p>
      <p>
        "You stay where you stand water bleeder."  The melon man barked as he produced a cable knife from the sleeve of his tunic.  The cable whirred to life with a soft whine. "Drop those shineys, they will stay with me tonight, and then tomorrow I will find them a nice home.  You come back in two nights and I can give you ten thousand, or I can bleed you right here and sell you to the fish mongers to pay for the trouble you caused me."
      </p>
      <p>
        From under his cloak Dathos thumbed a small toggle on his belt.  The unmistakeable static pop of a membrane springing to life sizzled in the air.  Though the melon man could not see it, he well knew the sound.  His eyes narrowed as his teeth clenched tighter.
      </p>
      <p>
        "You have a reputation melon barker.  I have not come to you unprepared."  Dathos explained.  Calmly he lifted his cloak to the side displaying the concussion shaft strapped to his thigh. "As you say, if we do not end this quickly we will be drawing attention.  I need the chips now.  If you don't have enough let me pass and we can both go on about our business."
      </p>
      <p>
        "Maybe we can come to an understanding."  The Keeper said slowly, his one good eye traversing the length of Dathos in reappraisal.  He kept the knife in front, between himself and Dathos.  "I can give you 20000 chips today.  You go back where you find those pretties and get me a dozen more and I can have 200 before the Torn Moon."
      </p>
      <p>
        "I will not be going back there."  Dathos said with more resolution than he intended to.  "These three are all there is going to be.  These are fourth dynasty and easily worth the price I ask.  If you do not intend to pay I am sure the Fortune Teller will."
      </p>
      <p>
        At that the door exploded.  Slivers of wood sprayed through the air, the door itself flew across the room.  Light poured in blinding Dathos for a moment as he strained to adjust.  The two Walker fish merchants with bolts drawn stood in the light, filling the hole that the moment before had been the door.  Each had plainly visible the Grey Rings that denoted their rank.  The rings that moments ago had been hidden away from view.
      </p>
      <p>
        Though it seemed much longer, for a fraction of a second all four stood in silent assesment of each other.  Time seemed to pass slowly as the merchant turned round to see what had become of his door and wall.  As he turned, the larger of the two one-time fish mongers wheeled toward the melon man.  A burst of blue white light exploded from the end of his bolt, filling the air with a thunderous crack and the smell of ozone.  The right half of the melon man's head vanished as it was engulfed in light and the stench of charred meat blended with the electric smell of the energy bolt.
      </p>
      <p>
        The melon man's ragged body slumped to the floor, bits of his tunic glowed yellow and smoking as his face hit the stone floor with a wet smack. the cable knife spun across the stones and rattled to the feet of the trigger man who only moments ago had been standing on a barrel boasting the quality of his eel.  The walker's shoulders shifted pointing the bolt squarely at Dathos.  For his part Dathos raised his hands to up showing the two walkers his empty palms.
      </p>
      <p>
        "Easy there gents, this man thought to lighten my purse.  Praise the Hood and the Parents you came in when you did."  He added a bit of a quiver to his voice, letting it crack at the end.
      </p>
      <p>
        "Is that right?"  The second walker said with feigned sympathy.  "Did you hear that?  We might be heroes."
      </p>
      <p>
        "Arent we just?"  The trigger man said with a chuckle.  "Let's have it.  Whatever you brought for him, you give to us.  You get to live in a dark hole, but you get to live.  And we close the book on the melon man plus one."
      </p>
      <p>
        "By the moon..."  Dathos began but was interrupted by a sneer from the second Walker.
      </p>
      <p>
        "Save it for the Red then."  The Grey Ring lunged toward Dathos.  In one movement Dathos hand swept back beneath his cloak and produced the concussion shaft. He struck it against the stone floor igniting a blue spark at its tip and pinging the air with a high pitched whine as it charged.
      </p>
      <p>
        The trigger man shouted something unintelligible and fired his bolt.  Dathos tried to swing himself out of the way, but failed to clear the path of the blue blast.  He felt the impact of the bolt on the invisible membrane around him.  The membrane released a sizzling sound and a chirp from the device on his belt alerted him that the membrane had taken a hit.
      </p>
      <p>
        "Watch it, he's got a flaming..." The triggerman shouted to his accomplice but Dathos was still in motion.  The blue spark from his concussion staff arced up from the floor and caught the second walker on the underside of his chin.  A thunderclap shook the walls of the little shack and the spark detonated into a brilliant white.  The Walker's head snapped back from the force of it spittle and blood spraying as he crumpled into the crate of leakign sour melons.  The guts of a dozen melons sprayed the walls and floor bathing the two standing men in the sour innards of the black and white spheres.
      </p>
      <p>
        The trigger man let out a cry and lunged for Dathos, his feet slid out from under him on the wet melon slime and he tumbled atop his companion.
      </p>
      <p>
        For his part, dathos leapt over the both of them and back through the doorway into the street.  The scene here was pandemonium.  Shopkeepers were scrambling to pull their wares from their displays.  Others were closing their awnings.  Would be shoppers were screaming and running in every direction at once.
      </p>
      <p>
        Dathos eyes shot to where he spied the two new Greys only a moment ago.  The walker that had been standing at attention was surging towards him, shoving screaming shoppers to his left and his right.  The Dweller was no where to be seen.  Dathos smacked the tip of his concussion shaft on the stone street and the blue spark jumped to life again.  With his weapon now armed, he nodded once quickly to the Grey Walker turned to his right and ran.  For the most part everyone was anxious to get out of his way.  He leapt over an aging Earth Mover once and sidestepped another Night Swimmer taking a moment to glance over his shoulder and see the Gray Walker closing the distance fast.
      </p>
      <p>
        Something hit the membrane with a sharp clattering noise, his belt chirped twice.  The membrane would not last much longer. Without stopping, he frantically cast about trying to find the source of the damage.  On the roof of one of the stalls the Grey Nest Dweller stood holding his bow with one hand, the other hand grabbing at something on his back, undoubtedly another arrow.  Dathos did not watch to find out.  He lunged to his left down the first alley he came across.  He had only a few seconds to create some distance before the Walker would be upon him.  He darted down one alley and then another, trying his best to zigzag through the maze of stalls and shop fronts, but Dathos did not know this part of the city well.  The Gray would certainly be at the advantage here.  As he ran through the maze he shot his gaze from one rooftop to another, waiting for the Dweller to re appear and soon enough he did.  His bow pulled back ready to loose.  Dathos was ready for him this time.  He pulled back his arm and hurled a small black and silver ket, no bigger than the chips the melon man had thrown at his feet a moment ago.  The ket landed softly on the roof of the shop.  It only took a fraction of a second for the Dweller to recognize what Dathos had just thrown at him and he was already in motion scrambling down into the alley, but it was a fraction too long.  The ket exploded with a sickening pop, the Nest Dweller took the edge of the blast and fell, his head cracking into the stone hard.  The Dweller didn't move but Dathos wasn't taking chances.  As he ran past he reached out with his weapon and pushed the pulsing blue spark against the back of the Dweller's head.  Another thunder clap and the Dwellers rag doll body skipped across the road away from the silver end of the shaft.
      </p>
      <p>
        If the Walker didn't know which way he had turned a moment ago he certainly did now.  It couldn't be helped.  He turned again down another alley this time on his right and came skidding to a halt in front of a brick wall.  He could hear shouting and cursing coming from behind, the Dweller would be here in an instant, he couldn't go back.  He spotted a sewer grate, he gave it a quick yank as best he could but the stone was too thick, it didn't even budge.  He thought of trying another chip of ket, but doubted it would make much more than a dent in the thing.
      </p>
      <p>
        He bit the finger of his glove pulling it off with his teeth, pumping his fist as he drew the glove from his hand to get the mucous flowing.
      </p>
      <p>
        Behind him he heard a small sound.
      </p>
      <p>
        "Over here!"
      </p>
      <p>
        He turned to see a young Sphere Maker.  He was not so good a judge of Makers as he might be, having not spent so much time among them himself, but this one appeared to be an adolescent.  She sat crouched behind a wide brick chimney, with her hands she beckoned him up to her. "Up here", she shouted though trying to whisper.
      </p>
      <p>
        Glancing back over his shoulder at the way he was about to go, and then to his right at where the cursing was getting louder Dathos made a quick judgement he hoped he wouldn't regret.  He ran toward the wall the young maker sat atop.  He jumped up, pushing off of a crate as best as he could to make as much altitude as he might. He smacked his gloveless hand against the brick hard, the mucous gave a little but held.  He reached up with his free hand just high enough for the maker to curl his fingers inside his own.
      </p>
      <p>
        Between his shuffling his ungloved hand and her pulling he just managed to clear the edge of the roof and lay flat as the cursing and shouting burst into the alley below him.  The maker put her finger against her lips and made a silent shushing motion.  Dathos did not need the reminder.  They laid there for what seemed an age listening, though it must have only been a moment.  Then the commotion returned to the street, and Dathos released the breath he hadn't realized he had been holding.  He started to stand, but the Maker motioned for him to stay flat, he obeyed.
      </p>
    </div>
  </div>

  <div class="section-header">
    <h2 @click="toggleVisibility('dathos_2')">Dathos Chapter 2</h2>
    <div v-if="visible.dathos_2">
      Dathos chapter 2
    </div>
  </div>

</template>

<script>

export default {
  name: 'TM_Story_Shorts_Component',
  data() {
    return {
      visible: {
        dathos_1: false,
        dathos_2: false,
      }
    }
  },
  computed: {},
  methods: {
    toggleVisibility(toggle_id) {
      console.log('toggling accordian bar for ', toggle_id)
      this.visible[toggle_id] = !this.visible[toggle_id];
      console.log(toggle_id + ' is now ', this.visible[toggle_id])
    },
  },
};
</script>

<style scoped>

</style>
