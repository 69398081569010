<template>
  <h3>Races of the Children</h3>

  <div>
    <h4>Earth Movers</h4>

    <p>
      Racial Ability: Earth Moving
      Height: 3’6”-4’4”
      Weight: 151-272 pounds
      Racial Distinctiveness: Partially subterranean,
    </p>
    <p>
      Bonus Modifiers: Hearing +3, Smell +1, Touch +4, Constitution +2, Strength +4
    </p>
    <p>
      Physical Description: Movers are somewhat shorter and stockier than the other races, but not
      dramatically so. They are covered in fur from head to foot colored in ranges of grey and brown,
      sometimes speckled with white as they age, particularly around the eyes and nose. Their
      fingernails are quite thick, and almost bone-like to the touch, though not pointed in to a claw.
      These nails are very useful for digging through earth without tools, though they often use tools in
      their digging to supplement their natural strength and these specialized nails.
    </p>
    <p>
      Community: Earth Movers are found throughout the lands of Torn Moon living in all varieties of
      communities, towns, cities and villages. When found in their “pure” state, as communities of
      Movers only, they prefer to live in subterranean villages they refer to as Naughts.
      Naughts are typically found in rocky terrain, and can be many generations old. Most often,
      Naughts are governed by a Yellow Hood. Less often, when a Naught is further from the central
      control of the Religious governments the Naught will be led by some variety of paternal figure.
      Usually one who is considered to be the oldest and the wisest among the current occupants,
      though on some occasions a younger battle hardened warrior, or some other kind of leader might
      be selected.
    </p>
    <p>
      Naughts export all variety of minerals on a regular basis, as well as random artifacts they
      discover buried within the bowels of the earth. In fringe communities, grey market artifacts are
      distributed without the consent or interference of the Ministry of Ecclesiastical affairs.
    </p>
    <p>
      Culture: Movers are known to be guarded and cautious around new faces. Historically, they
      were raided regularly for raw materials during the Maker-Walker wars, and as a result they have
      bred suspicion among their ranks. That is not to say they are unfriendly, and in fact by nature
      they are a curious people, many are quite educated as a result of this curiosity.
    </p>
    <p>
      Relation to the Religion: Movers have occupied positions at all levels in the Religion throughout
      recorded history. Often, they are called upon for their digging skills to assist in the unearthing of
      rare artifacts. The Brown Order consists primarily of Movers as their skills in digging and
      knowledge of the earth make them excellent dig masters, and excavators.
      Militarily a Mover can be particularly useful as a front line fighter as they are quite strong and
      sturdy. As a result, many who have risen through the ranks of the Legion are Earth Movers.
    </p>
  </div>

  <div>
    <h4>Land Walkers</h4>
    <p>
      Racial Ability: Unarmed Combat Piercing Damage
      Height: 5’2”-6’0”
      Weight: 111-232 pounds
      Racial Distinctiveness: Fastest ground speed, most endurance, can use sharp teeth in combat,
      best suited for living in wilderness environments, physically large.
    </p>
    <p>
      Bonus Modifiers: Hearing +1, Smell +1, Constitution +3, Speed +2, Thought +1.
    </p>
    <p>
      Physical Description: Walkers have a general canine appearance. An elongated snout, head to
      foot fur in colored in ranges of brown and grey with black brown or grey stripes. Typically they
      are tallest of the races, though also quite strong and quick.
    </p>
    <p>
      Community: Walkers prize family above all else. They live in communities called Clans; even
      while living in dense urban areas they group off to some extent and appoint a leader of their
      particular clan. The typical clan will consist of several dozen families. Though females might
      move from clan to clan as they marry, most often a male will stay in the same clan throughout his
      life. Most clans specialize in a certain trade, fishing, agriculture, etc, with a few individuals
      varying in profession to support the clan’s needs. An individual family’s dwelling most often
      consists of a round stone one room building. Additional smaller round stone buildings will be built
      in circles around the main dwelling as needed to support the family. Houses are seldom adjacent
      to each other, but nearly always within sight of another house.
    </p>
    <p>
      Culture: The Walkers culture is significantly changed since the Maker-Walker wars, having
      reverted to its roots for the most part, though fear of the marauding clans several generations
      past still lingers in some communities most raided by them. Modern Walkers are mostly peaceful,
      content to hunt or farm or fish and keep to themselves. Walkers are typically very open and
      friendly to outsiders, and are known for their hospitality, though some Clans still hold varying
      degrees of hatred and or mistrust of Sphere Makers.
      One of the most noted cultural differences among Walkers is their naming conventions. When
      she/he is born a Walker will be given a one syllable name. A second one syllable name will be
      taken from the first syllable of the name of whoever is leader of the clan at the time of the birth of
      the child. So if a Clan is led by a Walker named Fel-Bar, and parents within the Clan birth a child
      and names the child Yan, the child’s name through life will be Yan-Fel. In this way, both lineage
      of a clan can be shown, at the same time showing loyalty to the clan.
    </p>
    <p>
      Relation to the Religion: The strong family group upbringing, and Clan mentality lend well to
      military service and the Ministry of Personnel recruits among the Clans heavily. Walkers are
      perhaps the most numerous of the races of the Children, and as such have become a visible part
      of the Religion.
    </p>
  </div>

  <div>
    <h4>Nest Dwellers</h4>
    <p>
      Racial Ability: Tail Dexterity
      Height: 4’2”-5’5”
      Weight: 91-212 pounds
      Racial Distinctiveness: Very agile, prehensile tail to assist in climbing.
    </p>
    <p>
      Bonus Modifiers: Hearing +1, Touch +3, Charisma +1, Consititution +1, Coordination +3,
      Religion +1, Strength +2, Thought +2
    </p>
    <p>
      Physical Description: Dwellers are usually somewhat thinner than the other races, though not
      overly so. They have a long prehensile tail which is strong enough to support their weight for a
      time, though not quite so dexterous as to wield a weapon or tool. Dwellers are covered in fur of
      varying shades of brown.
    </p>
    <p>
      Community: Dwellers live in villages high in the tree tops, the buildings woven together from
      plant fibers which resemble the nests of some kinds of birds have earned them the name by
      which they are known. Dwellers are perhaps the most free spirited of the races of the Children.
      They have an allegiance to their village, their family, and their heritage, however they have no
      difficulty moving to another village, to a large urban area, or wandering around for a time.
      It is important to note however that some villages are close to sacred groves, in these instances
      fierce loyalty and protection toward the grove is common.
    </p>
    <p>
      Culture: Dwellers as a people are very open and friendly, they are known for their special blends
      of fruity ales which many villages export to other communities. Many Nest Dweller communities
      are also known for their scholarly pursuits. The nest dwellers claim to have invented the written
      language used by the children.
    </p>
    <p>
      Relation to the Religion: The Dwellers believe that the Parents have entrusted them with the
      tracking of time. To accomplish this, they raise sacred groves. These groves are composed of
      trees that stand out in some way to the surrounding area, trees that are not native are most often
      the way this is accomplished. Within the grove lives a peculiar religious order known as the
      Grove Guardians. Every day the guardians ceremonially mark a tree with some manner of ornate
      carving, the style of the carving varies by region. Different carvings are used for days during the
      torn moon event. For this reason often Dwellers will refer to days as “marks” rather than days,
      particularly in rural areas.
      Grove Guardians are fierce warrior zealots, it is not generally known what training methods are
      used, but whatever it might be it is lethally efficient. A passerby will not often see a grove
      guardian, but the sounds of their combat training can often be heard for some distance, in most
      instances it seems that the majority of their time is spent in training. The order of the grove
      guardians is largely dependant on the nearby village. During the Torn Moon event, offerings of
      food, supplies, weapons, and artifacts are taken by village elders to the center of the grove. This
      is the only time non guardians would be permitted to enter. Additionally, children born during the
      event are left behind in the grove to be raised in the order to guard the trees and their hallowed
      carvings until the parents return for an accounting of their stewardship.
    </p>
  </div>

  <div>
    <h4>Night Swimmers</h4>
    <p>
      Racial Ability: Underwater breathing, Hand Walk.
      Height: 4’2”-5’5”
      Weight: 151-272
      Racial Distinctiveness: Completely amphibious, able to move swiftly through water, able to slowly
      climb up vertical surfaces unaided, phosphorescent head bulb produces light when needed.
    </p>
    <p>
      Bonus Modifiers: Sight +2, Smell +2, Constitution +1, Religion +1, Strength +2, Thought +2.
    </p>
    <p>
      Physical Description: Smooth grey skinned elongated head with green bio-luminescent head
      bulb.
    </p>
    <p>
      Community: Swimmers do not typically make communities of their own. Shortly after birth,
      parents of Swimmers will abandon them in the sea, or sometimes in large bodies of fresh water.
      They will grow on their own living off of the things they find in their environment. Periodically
      however, Swimmers are instinctively drawn to certain areas of the world. The first time this will
      happen to an adolescent Swimmer is approximately 16 years after birth. Gatherings will welcome
      the new Swimmer warmly, and often other swimmers will present gifts. Sometimes the young
      Swimmer will have no knowledge of language, when this is the case; older Swimmers will often
      take the pup under their care for a year or so. Swimmers are quite intelligent and learn quickly,
      though often Swimmers have an accent that differs from the other races, and accents vary widely
      from swimmer to swimmer. After several months impulse to gather will abate and the swimmers
      will return to their previous endeavors. Swimmers do not marry, and usually only mate during
      these gatherings.
    </p>
    <p>
      Culture: Swimmers do not have much by way of a culture that is unique to them, as they do not
      rally around a community. Swimmers are often cautious, even around each other. They are
      intelligent, and many master trades easily and adapt to life in other communities, particularly in
      the larger mixed race cities.
    </p>
    <p>
      Relation to the Religion: Swimmers who grew to adolescence in the ocean have seen vast ruins
      of the Parentical civilization. As intelligent beings, this has caused many to wonder after the
      previous inhabitants. Many, upon emerging from the ocean for the first time quickly and
      passionately embrace the Religion, and work to involve themselves within it. Many of the
      ministries have appointed Swimmers as heads or important officials within them.
    </p>
  </div>

  <div>
    <h4>Sphere Makers</h4>
    <p>
      Racial Ability: Flight, Ethos
      Height: 4’0”-5’0”
      Weight: 71-192
      Racial Distinctiveness: Winged Flight, Refusal to use artifacts, ability to manipulate Life force by
      way of Ethos.
    </p>
    <p>
      Bonus Modifiers: Sight +1, Touch +1, Charisma +1, Religion +3, Thought +2
    </p>
    <p>
      Community: Maker communities vary, but most of the “pure” maker communities are somewhat
      nomadic, wandering from area to area over a generations old route, establishing temporary
      settlements as they go. Communities of Makers are referred to as Tribes. Makers can often tell
      one tribe from another by the distinctive facial markings makers are born with. In the past,
      Makers mostly kept to themselves, over the course of the Maker-Walker wars however, they
      began to expand and integrate into the larger communities and take a more active role in the
      Religion as it is practiced by the other races.
    </p>
    <p>
      Culture: Makers are great artists, particularly in the field of textiles. They produce fabrics made
      from the silk of the ryl and rhen spiders known as lew. This fabric is very strong and very light
      which suits them well as it is less impeding on flight. Makers are also the primary consumers of
      the Movers orkett market, as they prefer the lighter metal. Most makers maintain strict adherence
      to the Religion. Most often in outlying communities of other races, Religious adherence is lax or
      non existent. Not so with the Makers, outlying communities may have some differing traditions,
      and in some cases variations on themes and mythology, but each community adheres strictly to
      the religion as they understand it.
    </p>
    <p>
      Relation to the Religion: Makers have a distinctive belief system in relation to the Religion, they
      hold to a story that when the Parents ascended, they granted the Makers “The Gift” which is their
      ability to manipulate and control life force by way of Ethos. It is their belief that using the Parents
      technology would be to reject The Gift, or to say to the parents that The Gift was not good
      enough. Makers shun the use of technology for themselves, though most have developed a
      tolerance for the other races using it, this is justified to them by the absence of The Gift in the
      “lesser” races.
    </p>
  </div>

</template>

<script>

export default {
  name: 'TM_Story_Races_Component',
  data() {
    return {
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
