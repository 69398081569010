<template>
  <div class="move_block">
    <input type="text" v-model="movement.title"
           @change="$emit('append_log', ' Movement title changed to ' + $event.target.value + '\n')"/>
    Full (yards) <input type="number" v-model="movement.full" class="standard_short_input"
                        @change="$emit('append_log', movement.title + ' Full movement changed to ' + $event.target.value + '\n')"/>
    Combat (yards) <input type="number" v-model="movement.combat" class="standard_short_input"
                          @change="$emit('append_log', movement.title + ' Combat movement changed to ' + $event.target.value + '\n')"/>
    <button v-if="default_movements.default_movement_titles.indexOf(movement.title) === -1" class="add_more_button"
            @click="delete_me">Delete
      Movement
    </button>
  </div>
</template>

<script>
import defaults from "@/data/torn_moon_data/default_movement.json"

export default {
  props: ['modelValue']
  ,
  data() {
    return {
      default_movements: defaults
    }
  },
  computed: {
    movement() {
      console.log(`computed movement: ${JSON.stringify(this.modelValue)}`)
      return this.modelValue
    }
  },
  methods: {
    delete_me() {
      this.$emit('append_log', JSON.stringify(this.movement) + ' deleted from movement.\n')
      this.$emit('delete_movement', this.movement)
    }
  },
};
</script>

<style scoped>
.move_block {
  display: flex;
  align-items: center;
  justify-content: center;
}

.move_block > * {
  margin: 0 10px; /* Adjust the value as needed */
}

</style>
