<template>
  <h3>Gallery</h3>
  <div >
    <img src="@/assets/torn_moon/images/gallery/fight.jpg" alt="The Fight" class="gallery_image"/>
  </div>
  <div >
    <img src="@/assets/torn_moon/images/gallery/main_logo.jpg" alt="Text" class="gallery_image"/>
  </div>
  <div >
    <img src="@/assets/torn_moon/images/gallery/Races.jpg" alt="races" class="gallery_image"/>
  </div>
  <div >
    <img src="@/assets/torn_moon/images/gallery/ryanSig.jpg" alt="Ryan" class="gallery_image"/>
  </div>
  <div >
    <img src="@/assets/torn_moon/images/gallery/maker_head.jpg" alt="Maker Face" class="gallery_image"/>
  </div>

  <div>
    <img src="@/assets/torn_moon/images/gallery/bowedhead.jpg" alt="Bowed Head" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/Collage.jpg" alt="Collage" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/creepyrobot.jpg" alt="Robot" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/Collage2.jpg" alt="Collage2" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/cover.jpg" alt="Cover" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/doodle.jpg" alt="Doodle" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/dweller.jpg" alt="Dweller" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/dwellerpaper.jpg" alt="Dweller Paper" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/dwellerprofile.jpg" alt="Dweller Profile" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/dwellerprofilecolor.jpg" alt="Dweller Profile Color"
         class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/femalepow.jpg" alt="Pow" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/logo.jpg" alt="Logo" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/Maker.jpg" alt="Maker" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/mitrasella.jpg" alt="The Tower" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/mix.jpg" alt="Mix" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/moon.jpg" alt="Moon" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/glove.jpg" alt="Glove" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/swimmer.jpg" alt="Swimmer" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/ninter_pow.jpg" alt="Pow Again" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/old_swimmer.jpg" alt="Old Swimmer" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/predator.jpg" alt="Predator" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/relgattasbase.jpg" alt="Base" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/relgattaxp.jpg" alt="Relgatta" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/robot.jpg" alt="robot" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/sixthson.jpg" alt="Sixth Son" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/Sphere_Maker.jpg" alt="Sphere Maker" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/spiderbot.jpg" alt="Bot" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/swimmer&eel.jpg" alt="Eels" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/swimmerpaper.jpg" alt="Swimmer paper" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/swimmerprofile.jpg" alt="Swimmer Profile" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/Terrapi.jpg" alt="Terrapi" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/terrat_concept.jpg" alt="Terrat" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/TMNDcover.jpg" alt="Cover" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/totem.jpg" alt="Totem" class="gallery_image"/>
  </div>
  <div>
    <img src="@/assets/torn_moon/images/gallery/walker.jpg" alt="Walker" class="gallery_image"/>
  </div>
  <div >
    <img src="@/assets/torn_moon/images/gallery/administrator.jpg" alt="The Admin" class="gallery_image"/>
  </div>

</template>

<script>

export default {
  name: 'Docs_Gallery_Component',
  data() {
    return {
      // path: 'assets/torn_moon/gallery/',
      // files: ['@/assets/torn_moon/images/gallery/administrator.jpg'],
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

.gallery_image {
  max-width: 1000px;
  max-height: 1000px;
}

</style>
