<template>
  <div class="skill_block" @click="select_me">
    <div class="skill_header">
      <span class="skill_title">{{ skill_obj.title }}</span> &nbsp;
      <span>{{skill_obj.formula}}</span> &nbsp;
      <span>{{skill_obj.base}}</span>
    </div>
    <div class="skill_details">
      <span >{{skill_obj.description}}</span> &nbsp;
      <span v-if="skill_obj.limitation">{{skill_obj.limitation}}</span>&nbsp;
      <span v-if="skill_obj.bonus">{{skill_obj.bonus}}</span>
    </div>

  </div>
</template>

<script>
export default {
  props: ['skill_obj', 'attributes']
  ,
  methods: {
    select_me(){
      let skill = JSON.parse(JSON.stringify(this.skill_obj))
      skill.level = 0
      skill.hash = 0
      console.log("skill selected: ", skill)
      this.$emit('skill_selected', skill);
    }
  },
};
</script>

<style scoped>
.skill_block {
  border: 1px solid #ccc;
  margin-bottom: 5px;
}
.skill_title {
  font-weight: bold;
}
</style>
