<template>
  <div class="nav-box">
    <div class="char-gen-nav"
         v-for="(section, index) in sections"
         :key="index"
         @click="select_section(section, index)"
         :class="{ selected_subsection: selected_section === section.key }"
    >
      {{ section.title }}
    </div>
  </div>
  <hr>
  <div class="eq-content">
    <TM_EQ_General_Component v-if="this.$store.state.view.section === 'general'"></TM_EQ_General_Component>
    <TM_EQ_Sacred_Component v-if="this.$store.state.view.section === 'sacred-equipment'"></TM_EQ_Sacred_Component>
    <TM_EQ_Basic_Weapons_Component v-if="this.$store.state.view.section === 'common'"></TM_EQ_Basic_Weapons_Component>
    <TM_EQ_Sacred_Weapons_Component v-if="this.$store.state.view.section === 'sacred-weapons'"></TM_EQ_Sacred_Weapons_Component>
    <TM_EQ_Ammo_Component v-if="this.$store.state.view.section === 'ammo'"></TM_EQ_Ammo_Component>
    <TM_EQ_Etheria_Component v-if="this.$store.state.view.section === 'etheria'"></TM_EQ_Etheria_Component>
    <TM_EQ_Spheres v-if="this.$store.state.view.section === 'spheres'"></TM_EQ_Spheres>
    <TM_EQ_Armor_Component v-if="this.$store.state.view.section === 'armor'"></TM_EQ_Armor_Component>
    <TM_EQ_Sacred_Armor_Component v-if="this.$store.state.view.section === 'sacred-armor'"></TM_EQ_Sacred_Armor_Component>
    <TM_EQ_Power_Cells v-if="this.$store.state.view.section === 'cells'"></TM_EQ_Power_Cells>
    <TM_EQ_Ket_Component v-if="this.$store.state.view.section === 'ket'"></TM_EQ_Ket_Component>
    <TM_EQ_Transport_Component v-if="this.$store.state.view.section === 'tranport'"></TM_EQ_Transport_Component>

  </div>

  <br>
  <hr>
</template>

<script>
import TM_EQ_General_Component from "@/components/torn_moon/docs_site/equipment/TM_EQ_General_Component.vue";
import TM_EQ_Sacred_Component from "@/components/torn_moon/docs_site/equipment/TM_EQ_Sacred_Component.vue";
import TM_EQ_Basic_Weapons_Component
  from "@/components/torn_moon/docs_site/equipment/TM_EQ_Basic_Weapons_Component.vue";
import TM_EQ_Sacred_Weapons_Component
  from "@/components/torn_moon/docs_site/equipment/TM_EQ_Sacred_Weapons_Component.vue";
import TM_EQ_Ammo_Component from "@/components/torn_moon/docs_site/equipment/TM_EQ_Ammo_Component.vue";
import TM_EQ_Etheria_Component from "@/components/torn_moon/docs_site/equipment/TM_EQ_Etheria_Component.vue";
import TM_EQ_Spheres from "@/components/torn_moon/docs_site/equipment/TM_EQ_Spheres.vue";
import TM_EQ_Armor_Component from "@/components/torn_moon/docs_site/equipment/TM_EQ_Armor_Component.vue";
import TM_EQ_Sacred_Armor_Component from "@/components/torn_moon/docs_site/equipment/TM_EQ_Sacred_Armor_Component.vue";
import TM_EQ_Power_Cells from "@/components/torn_moon/docs_site/equipment/TM_EQ_Power_Cells.vue";
import TM_EQ_Ket_Component from "@/components/torn_moon/docs_site/equipment/TM_EQ_Ket_Component.vue";
import TM_EQ_Transport_Component from "@/components/torn_moon/docs_site/equipment/TM_EQ_Transport_Component.vue";

export default {
  name: 'Docs_Equipment_Component',
  components: {
    TM_EQ_Ammo_Component,
    TM_EQ_General_Component,
    TM_EQ_Sacred_Component,
    TM_EQ_Basic_Weapons_Component,
    TM_EQ_Sacred_Weapons_Component,
    TM_EQ_Etheria_Component,
    TM_EQ_Spheres,
    TM_EQ_Armor_Component,
    TM_EQ_Sacred_Armor_Component,
    TM_EQ_Power_Cells,
    TM_EQ_Ket_Component,
    TM_EQ_Transport_Component
  },
  data() {
    return {
      sections: [
        {key: 'general', title: 'General Equipment'},
        {key: 'sacred-equipment', title: 'Artifact Equipment'},
        {key: 'common', title: 'Common Weapons'},
        {key: 'sacred-weapons', title: 'Sacred Weapons'},
        {key: 'etheria', title: 'Etheria'},
        {key: 'spheres', title: 'Spheres'},
        {key: 'armor', title: 'Armor'},
        {key: 'sacred-armor', title: "Sacred Armor"},
        {key: 'ammo',title: 'Ammunition'},
        {key: 'cells',title: 'Power Cells'},
        {key: 'ket',title: 'Ket Equipment'},
        {key: 'tranport',title: 'Transportation'},
      ],
      selected_section: 'general',
      index: 0
    };
  },
  mounted(){
    console.log('equipment got mounted')
    this.select_section(this.sections[0], 0)
  },
  methods: {
    select_section(section, index) {
      console.log('setting the selected section as: ', section)
      this.$store.commit("set_section", section.key)
      this.selected_section = section.key;
      console.log('setting selected index as: ', index)
      this.index = index
    }
  }
};
</script>

<style scoped>

.nav-box {
  display: flex;
  justify-content: center;
}
.char-gen-nav {
  font-family: "Georgia";
  font-size: larger;
  margin-right: 15px;
}

:deep(.basic_table) {
  border: 1px solid black;
  border-collapse: collapse;
}

:deep(.basic_table th) {
  border-bottom: 1px solid black;
  padding: 8px;
  text-align: left;
}

:deep(.basic_table td) {
  border: 1px solid darkslategray;
  padding: 4px;
  text-align: left;
}

:deep(.basic_table th) {
  background-color: #f2f2f2;
}

.char-gen-content {
  text-align: left;
}

.selected_subsection {
  color: forestgreen;
}
</style>
