<template>
  <div class="hp_block">
    <div v-if="is_default" class="hp_title">{{ hp_obj.title }}</div>

    <div v-else class="hp_title">
      <input type="text" v-model="hp_obj.title"
             @change="log_change('Title', $event.target.value)"/>
      &nbsp;
      <button @click="$emit('hp_delete', hp_obj)">Delete Location</button>
    </div>

    <div class="hp_content">
      Wound Rating: Maximum <input type="number" v-model="hp_obj.wound_rating.max" class="standard_medium_input"
                                   @change="log_change('Maximum Wound Rating', $event.target.value)"/>
      Current <input type="number" v-model="hp_obj.wound_rating.current" class="standard_medium_input"
                     @change="log_change('Current Wound Rating', $event.target.value)"/>
      <button>Damage</button>
      <button>Heal</button>
    </div>
    <div class="hp_content">
      Hit Points: Maximum <input type="number" v-model="hp_obj.hp.max" class="standard_medium_input"
                                 @change="log_change('Maximum Hit Points', $event.target.value)"/>
      Current <input type="number" v-model="hp_obj.hp.max" class="standard_medium_input"
                     @change="log_change('Current Hit Points', $event.target.value)"/>
      <button>Damage</button>
      <button>Heal</button>
    </div>

    <div v-if="hp_obj.armor">

    </div>
  </div>
</template>

<script>
import hp_defaults from "@/data/torn_moon_data/default_hp.json"

export default {
  props: ['modelValue'],
  data() {
    return {}
  },
  computed: {
    hp_obj() {
      return this.modelValue
    },
    is_default() {
      return hp_defaults.default_hp_location_titles.indexOf(this.hp_obj.title) !== -1
    }
  },
  methods: {
    log_change(target, value) {
      this.$emit('append_log', this.hp_obj.title + ' ' + target + ' changed to ' + value + '\n')
    }
  },
};
</script>

<style scoped>
.hp_block {

}

.hp_content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.hp_content > * {
  margin: 0 10px; /* Adjust the value as needed */
}

.hp_title {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
