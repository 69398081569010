<template>
  <div class="desc_block">
    <input type="text" v-model="desc.title"
           @change="$emit('append_log', ' Description title changed to ' + $event.target.value + '\n')"/>
    :
    <input type="text" v-model="desc.value"
           @change="$emit('append_log', ' Description value changed to ' + $event.target.value + '\n')"/>
      <button v-if="default_descriptions.default_description_titles.indexOf(desc.title) === -1" class="add_more_button"
            @click="$emit('delete_desc')">Delete</button>
  </div>
</template>

<script>
import defaults from "@/data/torn_moon_data/default_description.json"

export default {
  props: ['modelValue']
  ,
  data() {
    return {
      default_descriptions: defaults
    }
  },
  computed: {
    desc() {
      console.log(`computed description: ${JSON.stringify(this.modelValue)}`)
      return this.modelValue
    }
  },
  methods: {
  },
};
</script>

<style scoped>
.desc_block {
  display: flex;
  align-items: center;
  justify-content: center;
}

.desc_block > * {
  margin: 0 10px; /* Adjust the value as needed */
}

</style>
