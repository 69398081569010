<template>
  <div>
    <h1>Account</h1>
    <div class="account-info">
<!--      <div class="account-picture">-->
<!--        <img :src="account.picture" alt="Account Picture">-->
<!--      </div>-->
      <div class="account-details">
        <p><strong>Account Name:</strong> {{ user.account }}</p>
        <p><strong>User Alias:</strong> {{ user.alias }}</p>
        <p><strong>Member since:</strong> {{ user.created_date }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Account_View',
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    console.log('account view is mounted')
    this.$store.commit("set_page", 'account')
  },
};
</script>

<style>
.account-info {
  display: flex;
  align-items: center;
}

.account-picture img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.account-details {
  margin-left: 20px;
}
</style>
