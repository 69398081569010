<template>
  <h3>Orders and Ranks of the Religion</h3>
  <h4>White Order</h4>
  <p>
    <span class="rank white_rank">The White Hood:</span> Regional head of government. There are three White Hoods, one
    for the West,
    East, and Central regions of the continent. The hoods meet in council and often coordinate
    together, but each is the ultimate authority in his region.
  </p>
  <p>
    <span class="rank white_rank">White Hand:</span> The White Hand enforces the "Will of the White." They inspect
    business and
    homes periodically to see that things are in keeping with Religious standards and practices.
    Seldom do they wear the mark of their position in public, so as to more easily discover violations
    of The Will.
  </p>
  <p>
    <span class="rank white_rank">White Blades: </span> The White Blades are a highly trained paramilitary organization
    operating outside
    of the Legion. They answer directly to the White and are responsible for the safety of the White.
    Often they are sent on special errands when discretion is required.
  </p>
  <p>
    <span class="rank white_rank">White Star: </span>The White Star oversees the judicial operations of the government
    including law
    enforcement, legal proceedings, etc.
  </p>
  <p>
    <span class="rank white_rank">White Sun: </span>Arguably the second most powerful in the chain of command as he
    oversees much
    of the day to day operations of government as it is seen by the people.
  </p>
  <p>
    <span class="rank white_rank">Minister of Public Enlightenment:  </span>The minister of Propaganda ensures that
    people receive the
    information that is the Will of the White.
  </p>
  <p>
    <span class="rank white_rank">White Spear: </span>Delivers the information concerning the Will of the White to the
    Legion, ensures
    the Legion adheres to it, and works to prevent military uprising.
  </p>
  <p>
    <span class="rank white_rank">Eye of the White: </span>Observes the behavior of the legion and reports to the White
    Spear
  </p>

  <h4>Red Order - Law</h4>
  <p>
    <span class="rank red_rank">Minister of Justice: </span>The Minister of Justice is the head of the police primarily,
    but also
    determines which cases are to be tried before Those Who Stand in Judgment.
  </p>
  <p>
    <span class="rank red_rank">Writers of the Law (Red Band): </span>Though not responsible for direct creation of
    policy, the writers
    of the law interpret the speeches and sayings of the White and create laws based on those
    statements.
  </p>
  <p>
    <span class="rank red_rank">Those Who Stand in Judgment (all red): </span>Those who stand in judgment hear the pleas
    of the
    accused in the courtroom, and weigh them against the evidence provided by the Accusers.
  </p>
  <p>
    <span class="rank red_rank">Accusers (Red Arms): </span>The Accusers take the evidence of criminal activity compiled
    by the Grey
    Hands and present it to Those Who Stand in Judgment
  </p>
  <p>
    <span class="rank red_rank">Red Sash:  </span>High ranking, but not distinguished minions of the Minister of Justice
  </p>

  <h4>Blue Order - Intelligence</h4>
  <p>
    <span class="rank blue_rank">Minister of Intelligence: </span>The Minister of Intelligence oversees the gathering of
    information,
    primarily with apostate organizations, but operating within the general populace as well.
  </p>
  <p>
    <span class="rank blue_rank">Listeners: </span>Adept at trickery and double talk, listeners are employed to seek out
    enemies of the
    White, primarily among the apostate. The Listeners wear no mark of service.
  </p>
  <p>
    <span class="rank blue_rank">The Whites Thieves: </span>Adept burglars and thieves used to obtain evidence or
    artifacts at the
    disposal of the Minister of Intelligence
  </p>
  <p>
    <span class="rank blue_rank">Blue Sash: </span>High ranking, but not distinguished minions of the Minister of
    Intelligence
  </p>

  <h4>Grey Order - Policing</h4>
  <p>
    <span class="rank grey_rank">Minister of Enforcement: </span>Oversees the police forces and the enforcement of the
    Whites Will.
  </p>
  <p>
    <span class="rank grey_rank">Civil Investigators (Grey Hands): </span>Used to investigate crimes, primarily not of
    religious nature.
    Theft, assault, murder, but occasionally highly skilled Hands are called on to infiltrate apostate
    groups or seek out other iniquity.
  </p>
  <p>
    <span class="rank grey_rank">Patrolmen (Grey Bands):  </span>The basic unit of law enforcement, these lightly armed
    men and
    women watch for crime on the streets of populated areas.
  </p>
  <p>
    <span class="rank grey_rank">Urban Enforcement (Grey Blades): </span>When additional force is required, the Grey
    Blades are
    called upon to exercise the Will of the White.
  </p>
  <p>
    <span class="rank grey_rank">Protection (Grey Shields): </span>The Grey Shields operate as the guards for high
    ranking
    government officials, or other important figures that do not merit the protection of the White
    Blades
  </p>
  <p>
    <span class="rank grey_rank">Undercover Operations (Grey Rings): </span>The Grey Rings are seldom seen wearing their
    rings
    except in ceremonies or as identification to make an arrest. They operate undercover to seek out
    undesirable activities within the communities.
  </p>
  <p>
    <span class="rank grey_rank">Grey Sash: </span>High ranking, but not distinguished minions of the Minister of
    Enforcement.
  </p>

  <h4>Yellow Order - Administration</h4>
  <p>
    <span class="rank yellow_rank">Governor (Yellow Hood): </span>Governors act as local Executive authority. Sometimes
    a Governor
    will control vast rural areas, while others control mere districts in a city.
  </p>
  <p>
    <span class="rank yellow_rank">Minister of Inter-Regional Affairs: </span>Directs affairs that cross regional
    boundaries and oversees
    relations between his region, and the other two.
  </p>
  <p>
    <span class="rank yellow_rank">Yellow Sash: </span>High ranking, but not distinguished minions of the Ministers of
    the Yellow.
  </p>
  <p>
    <span class="rank yellow_rank">Minister of Regional Affairs: </span>Oversees the needs of his own region and its
    security
  </p>
  <p>
    <span class="rank yellow_rank">Minister of Finance: </span>The Minister of Finance is responsible to distribute the
    chips that are
    discovered in digs, and to regulate how many are available in the public, regulating inflation. He
    is also responsible for all banking activity within his region.
  </p>
  <p>
    <span class="rank yellow_rank">Bank Master (Yellow Cap): </span>Chief operations manger of a bank.
  </p>
  <p>
    <span class="rank yellow_rank">Deskman (Yellow Band): </span>Trusted bank employee.
  </p>
  <p>
    <span class="rank yellow_rank">Collector of Debts (Yellow Ring): </span>Yellow Rings are responsible for reclaiming
    bad debts, or
    capturing and imprisoning those who do not pay their debts.
  </p>
  <p>
    <span class="rank yellow_rank">Minister of Commerce: </span>Oversees commerce in his region
  </p>
  <p>
    <span class="rank yellow_rank">Minister of Agriculture: </span>Oversees the agricultural endeavors of the region,
    ensures that enough
    food is being produced and manages the prices of that food.
  </p>
  <p>
    <span class="rank yellow_rank">Health Enforcer: </span>Inspects agricultural enterprises to ensure public health.
  </p>
  <p>
    <span class="rank yellow_rank">Minister of Post: </span> Oversees the postal system within the region.
  </p>
  <p>
    <span class="rank yellow_rank">Post Carrier (Yellow Boots): </span>Carries the post.
  </p>
  <p>
    <span class="rank yellow_rank">Minister of Medicine: </span>Oversees the availability, price, and distribution of
    health care, as well as
    the research into new Artifacts that might yield medicinal benefits.
  </p>
  <p>
    <span class="rank yellow_rank">Doctor of the People (Yellow Cloak): </span>A medical doctor, veterinarian, or
    dentist.
  </p>
  <p>
    <span class="rank yellow_rank">Assistant to the Doctor (Yellow Wrists): </span>Yellow cloaks assistant.
  </p>

  <h4>Brown Order</h4>
  <p>
    <span class="rank brown_rank">Minister of Ecclesiastical Affairs: </span>Responsible for acquiring, understanding,
    and distributing
    artifacts.
  </p>
  <p>
    <span class="rank brown_rank">Dig Master (Brown Belt): </span>Head of an archeological dig.
  </p>
  <p>
    <span class="rank brown_rank">Lore Keeper (Brown Hoods): </span>Brown Hoods attempt to interpret the information
    discovered in
    the digs.
  </p>
  <p>
    <span class="rank brown_rank">Readers of History (Brown Rings): </span>Masters of language
  </p>
  <p>
    <span class="rank brown_rank">Recorder (Brown Bands): </span>Catalogue and compare findings at digs.
  </p>
  <p>
    <span class="rank brown_rank">Excavator (Brown Gloves): </span>Expert digger.
  </p>
  <p>
    <span class="rank brown_rank">Brown Sash: </span>High ranking, but not distinguished minions of the Minister of
    Ecclesiastical Affairs
  </p>

  <h4>Sky Order - Legion Support</h4>
  <p>
    <span class="rank sky_rank">Minister of Engineers: </span>Responsible for the training, distribution and equipping of legion
    engineers
  </p>
  <p>
    <span class="rank sky_rank">Minister of Supply: </span>Oversees the supply of the military and coordinates with the Ministry of
    Ecclesiastical Affairs on behalf of the Legion.
  </p>
  <p>
    <span class="rank sky_rank">Minister of Arms: </span>Distributes Artifacts as needed to the military, and oversees their training with
    those arms.
  </p>
  <p>
    <span class="rank sky_rank">Minister of Field Medicine: </span>Head of all Legion Medicine.
  </p>
  <p>
    <span class="rank sky_rank">Blue Hoods: </span>Legion medical personnel.
  </p>

  <h4>Green Order - Legion Administration</h4>
  <p>
    <span class="rank green_rank">Minister of Personnel: </span>Head of recruiting and conscripting for the Legion.
  </p>

  <h4>Black Order - The Legion</h4>
  <p>
    <span class="rank black_rank">Black Shield: </span>Military commander of the Legion.
  </p>
  <p>
    <span class="rank black_rank">Black Hood: </span>1st assistant to the shield.
  </p>
  <p>
    <span class="rank black_rank">Black Cape: </span>2nd assistant to the shield.
  </p>
  <p>
    <span class="rank black_rank">Black Staff: </span>Quartermaster, responsible for distributing weapons and materials to the smaller
    units of the Legion.
  </p>
  <p>
    <span class="rank black_rank">Black Band: </span>Leader of two Cohorts, except in the case of the 1st Cohort.
  </p>
  <p>
    <span class="rank black_rank">Black Spear: </span>Leader of a Formation.
  </p>
  <p>
    <span class="rank black_rank">Black Blade: </span>Leader of a Century, these leaders are the backbone of the legion.
  </p>
  <p>
    <span class="rank black_rank">Black Helm: </span>Leader of a Ten.
  </p>
  <p>
    <span class="rank black_rank">Black Sash: </span>Soldier
  </p>
  <p>
    <span class="rank black_rank">Black Wrist: </span>Support troops. Blacksmiths, cooks, clothes washers, stablemen, etc.
  </p>
  <p>
    <span class="rank black_rank">Black Hammer: </span>Legion Engineer
  </p>
  <p>
    <span class="rank black_rank">Mouth of the Shield: </span>Communication coordinator, including artifact communication, or acting
    as runner.
  </p>
  <p>
    <span class="rank black_rank">Standard Bearer: </span>Those who have been wounded in honorable combat, or are beyond the age
    of warfare, are granted the honor of carrying the Century or Cohort Colors.
  </p>

</template>

<script>

export default {
  name: 'TM_Story_Ranks_Component',
  data() {
    return {}
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

.rank {
  font-weight: bolder;
}

</style>
