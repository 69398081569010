<template>
  <h3>Spheres</h3>
  <h3>Sacred Artifact Equipment</h3>
  <p>
    Spheres can only be activated by Sphere Makers, under no condition can any other race activate a sphere.
  </p>

  <p>
    Unless otherwise noted, all spheres require the user to expend life force to activate the special properties of
    that item.
  </p>

  <p>
    Distribution of Spheres are tightly controlled, as such purchasing these items at character generation time
    requires a succesful availability roll.
  </p>

  <p>
    Spheres are the hallmark of the Sphere Maker people. Every sphere looks identical to every
    other sphere. They are each 4 inches in diameter, appearing to be made of glass and filled with
    a strange translucent smoky liquid substance of a pale blue color. Once a sphere is handled it is
    apparent they are not made of glass however. They weigh literally nothing, as ephemeral as a
    soap bubble, but as substantial as steel. The texture is flawlessly smooth. When
    activated, they seem immune to gravity, and unaffected by air currents of any kind. They heed
    only inertia. Hovering in place, or floating along a straight course at exactly the speed they began
    with until they are interrupted in their path. When their inertial travel is altered, in even the
    slightest degree, they activate the power held within.
  </p>

  <p>
    Non Makers have no way of identifying what type of activity a sphere might do prior to its ignition.
    Sphere Makers might be able to sense a spheres potential using Ethos.
  </p>

  <p>
    Unless otherwise stated, spheres emit their effect over an 8 foot radius from the point of impact.
  </p>


    <h4>Spheres</h4>
  <table class="basic_table">
    <tr>
      <th>
        Sphere Name
      </th>
      <th>Description</th>
      <th>Price</th>
      <th>Availability</th>
      <th>Life Force</th>
    </tr>
    <tr v-for="(sphere, index) in spheres" :key="index">
      <td>{{ sphere.title }}</td>
      <td>{{ sphere.description }}</td>
      <td>{{ sphere.price || "N/A" }}</td>
      <td>{{ sphere.availability || "all" }}</td>
      <td>{{ sphere.life_force || "N/A" }}</td>
    </tr>
  </table>

  <p>
    Siege Weapons require the Etherial Seige skill.
  </p>

  <h4>Siege Weapons</h4>
  <table class="basic_table">
    <tr>
      <th>
        Weapon Name
      </th>
      <th>Description</th>
      <th>Price</th>
      <th>Availability</th>
      <th>Damage</th>
      <th>Damage Type</th>
      <th>Life Force</th>
    </tr>
    <tr v-for="(sw, index) in siege" :key="index">
      <td>{{ sw.title }}</td>
      <td>{{ sw.description }}</td>
      <td>{{ sw.price || "N/A" }}</td>
      <td>{{ sw.availability || "all" }}</td>
      <td>{{ sw.damage || "N/A" }}</td>
      <td>{{ sw.damage_type || "N/A" }}</td>
      <td>{{ sw.life_force || "N/A" }}</td>
    </tr>
  </table>


</template>

<script>
import etheria from "@/data/torn_moon_data/etheria.json"

export default {
  name: 'TM_EQ_Spheres',
  data() {
    return {
      spheres: etheria.spheres,
      siege: etheria.siege
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
