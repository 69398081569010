<template>
  <h3>Ammunition</h3>

  <h4>Standard Ammunition</h4>
  <div v-for="(category, index) in ammo" :key="index" >
    <h4>{{index}}</h4>
    <table class="basic_table">
      <tr>
        <th>
          Ammunition Name
        </th>
        <th>Description</th>
        <th>Price</th>
        <th>Availability</th>
        <th>Damage</th>
        <th v-if="category[0].damage_type">Damage Type</th>
      </tr>
      <tr v-for="(eq_obj, index) in category" :key="index">
        <td>{{ eq_obj.title }}</td>
        <td>{{ eq_obj.description }}</td>
        <td>{{ eq_obj.price || "N/A" }}</td>
        <td>{{ eq_obj.availability || "all"}}</td>
        <td>{{eq_obj.damage || "N/A"}} </td>
        <td>{{ eq_obj.dmg_type || "N/A"}}</td>
      </tr>
    </table>
  </div>



</template>

<script>
import weapons from "@/data/torn_moon_data/weapons.json"

export default {
  name: 'TM_EQ_Ammo',
  data() {
    return {
      ammo: weapons.ammunition
    }
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
