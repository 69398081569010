<template>
  <h3>Life Force</h3>
  <p>
    Life force is the energy that binds the body and soul together. When life force
    is completely drained, the result is death. Additionally Life force is used by Sphere Makers as a
    resource when they use Ethos and Etheria items. Life force points are determined as follows.
  </p>

  <ul>
    <li v-for="(race, index) in races" :key="index">
      {{race.title}}: {{hp_calc[race.name].life_force}}
    </li>
  </ul>


</template>

<script>
import gen from "@/data/torn_moon_data/char_generation.json"
import races from "@/data/torn_moon_data/playable_races.json"

export default {
  name: 'TM_Char_LF_Attributes',
  data() {
    return {
      hp_calc: gen.hp_calc,
      races: races
    }
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
